import { Fragment } from "react";
import { FaTimes } from "react-icons/fa";
// import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { SelectionDirection } from "../selectors/puzzleSelectionSelectors";
import { BoardModel } from "../selectors/puzzleSelectors";
import { PuzzleIndex } from "../selectors/userPuzzleSelectors";
import { PuzzleCellModel } from "./InteractivePuzzleCell";

const GridCellSelectionWrapper = styled.div`
  border: ${(p) => `2px solid ${p.theme.colors.selection}`};
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-radius: 5px;
  z-index: 1;
  user-select: none;
  pointer-events: none;
`;

const SelectionDirectionArrowBase = styled.div`
  position: absolute;
  z-index: 1;
  pointer-events: none;
  width: 0;
  height: 0;
`;

const SelectionDirectionArrowRight = styled(SelectionDirectionArrowBase)<{
  enabled: boolean;
}>`
  right: -9px;
  top: 50%;
  margin-top: -7px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid ${(p) => p.theme.colors.selection};
  opacity: ${(p) => (p.enabled ? 1.0 : 0.5)};
`;

const SelectionDirectionArrowDown = styled(SelectionDirectionArrowBase)<{
  enabled: boolean;
}>`
  bottom: -9px;
  left: 50%;
  margin-left: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid ${(p) => p.theme.colors.selection};
  opacity: ${(p) => (p.enabled ? 1.0 : 0.5)};
`;

const CloseX = styled(FaTimes)<{ tilesize: number }>`
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: 1px;
  right: 1px;
  border-top-right-radius: 2px;
  font-size: ${(p) => p.tilesize / 6}px;
  padding: ${(p) => p.tilesize / 25}px ${(p) => p.tilesize / 25}px;
  background: ${(p) => p.theme.colors.selection};
  color: ${(p) => p.theme.colors.background.main};
  border-bottom-left-radius: 3px;
`;

function indexShifted(index: PuzzleIndex, dx: number, dy: number): PuzzleIndex {
  return {
    x: index.x + dx,
    y: index.y + dy,
  };
}

function nextSlot(
  board: BoardModel,
  direction: SelectionDirection,
  current: PuzzleIndex
): PuzzleIndex | undefined {
  const dx = direction === "down" ? 0 : 1;
  const dy = direction === "right" ? 0 : 1;

  const loc = indexShifted(current, dx, dy);
  return validIndex(board, loc) ? loc : undefined;
}

function validIndex(board: BoardModel, index: PuzzleIndex): boolean {
  return board[index.y] && board[index.y][index.x];
}

export default function PuzzleCellSelection({
  cellModel,
  board,
  size,
  direction,
}: {
  size: number;
  cellModel: PuzzleCellModel;
  board: BoardModel;
  direction: SelectionDirection;
}) {
  const next = nextSlot(board, direction, cellModel.index) !== undefined;
  const occupied =
    cellModel.content !== undefined && cellModel.content !== "empty";
  return (
    <Fragment>
      <GridCellSelectionWrapper>
        {direction === "right" ? (
          <SelectionDirectionArrowRight enabled={next} />
        ) : (
          <SelectionDirectionArrowDown enabled={next} />
        )}
      </GridCellSelectionWrapper>
      {occupied ? <CloseX tilesize={size}></CloseX> : undefined}
    </Fragment>
  );
}
