import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import useDocumentTitle from "../../global/hooks/useDocumentTitle";
import AppContainer, { NavTitle } from "../common/AppContainer";
import { Spacer } from "../common/CommonElements";
import Loading from "../common/Loading";
import { PrimaryButtonLarge } from "../common/PrimaryButton";
import {
  boardForTag,
  buildBoard,
  buildTiles,
  tilesetForTag,
  tilesForBoard,
} from "../puzzle/selectors/puzzleSelectors";
import {
  randomBoard,
  randomTileset,
} from "../puzzle/selectors/randomPuzzleTag";
import { MenuOrBackButton } from "../puzzlePage/PuzzleNavBar";
import ViewOnlyPuzzleBoard from "../tutorial/ViewOnlyPuzzleBoard";
import RandomSelection from "./RandomSelection";
import TilesList from "./TilesList";

const RandomWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RandomContent = styled.div`
  max-width: 450px;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px; ;
`;

function Nav() {
  return (
    <Fragment>
      <MenuOrBackButton />
      <Spacer />
      <NavTitle>Random Puzzle</NavTitle>
    </Fragment>
  );
}

type TagsPerPageKey = { [key: string]: [string, string] };
const tagsForPageKeyAtom = atom<TagsPerPageKey>({
  key: "tagsForPageKeyAtom",
  default: {},
});

export default function SelectRandom() {
  const [boardTag, setBoardTag] = useState<string>();
  const [tilesetTag, setTilesetTag] = useState<string>();
  const navigate = useNavigate();

  const [tagsPerPage, setTagsPerPage] = useRecoilState(tagsForPageKeyAtom);
  const location = useLocation();
  useDocumentTitle(`GridWord - Random`);

  const setTags = useCallback(
    (board?: string, tileset?: string) => {
      if (board) setBoardTag(board);
      if (tileset) setTilesetTag(tileset);
      const b: string = (board || boardTag) as string;
      const t: string = (tileset || tilesetTag) as string;
      if (b && t) {
        setTagsPerPage({
          ...tagsPerPage,
          [location.key]: [t, b],
        });
      }
    },
    [boardTag, location.key, setTagsPerPage, tagsPerPage, tilesetTag]
  );

  const randomizeBoard = useCallback(() => {
    setTags(randomBoard());
  }, [setTags]);

  const randomizeTileset = useCallback(() => {
    setTags(undefined, randomTileset());
  }, [setTags]);

  useEffect(() => {
    const existing = tagsPerPage[location.key];
    if (existing) {
      setTilesetTag(existing[0]);
      setBoardTag(existing[1]);
    } else {
      randomizeBoard();
      randomizeTileset();
    }
  }, [boardTag, location.key, randomizeBoard, randomizeTileset, tagsPerPage]);

  return (
    <AppContainer nav={<Nav></Nav>} fullHeight={true}>
      <RandomWrapper>
        <RandomContent>
          <RandomSelection
            height={350}
            title="Board"
            onRandomize={randomizeBoard}
          >
            {boardTag ? (
              <React.Suspense fallback={<Loading />}>
                <BoardSection boardTag={boardTag}></BoardSection>
              </React.Suspense>
            ) : (
              <Loading />
            )}
          </RandomSelection>
          <RandomSelection title="Tiles" onRandomize={randomizeTileset}>
            {boardTag && tilesetTag ? (
              <React.Suspense fallback={<Loading />}>
                <TilesSection
                  boardTag={boardTag}
                  tilesetTag={tilesetTag}
                ></TilesSection>
              </React.Suspense>
            ) : (
              <Loading />
            )}
          </RandomSelection>
          <Footer>
            <PrimaryButtonLarge
              onClick={() => {
                if (boardTag && tilesetTag) {
                  navigate(`/game/${tilesetTag}-${boardTag}`);
                }
              }}
            >
              Play
            </PrimaryButtonLarge>
          </Footer>
        </RandomContent>
      </RandomWrapper>
    </AppContainer>
  );
}

function BoardSection({ boardTag }: { boardTag: string }) {
  const boardRecord = useRecoilValue(boardForTag(boardTag));
  const board = buildBoard(boardRecord.board);
  return (
    <ViewOnlyPuzzleBoard
      board={board}
      tilesByIndex={{}}
      uniqueId="board"
    ></ViewOnlyPuzzleBoard>
  );
}

function TilesSection({
  tilesetTag,
  boardTag,
}: {
  tilesetTag: string;
  boardTag: string;
}) {
  const tilesetRecord = useRecoilValue(tilesetForTag(tilesetTag));
  const boardRecord = useRecoilValue(boardForTag(boardTag));
  const tiles = buildTiles(tilesForBoard(boardRecord, tilesetRecord));
  return <TilesList tiles={tiles}></TilesList>;
}
