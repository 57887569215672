import { ReactNode } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "../../components/common/CommonElements";

export function useMaybeBackButton(): ReactNode | undefined {
  return useCanGoBack() ? <BackButton /> : undefined;
}

export function BackButton() {
  let navigate = useNavigate();
  return (
    <IconButton onClick={() => navigate(-1)}>
      <FaArrowLeft></FaArrowLeft>
    </IconButton>
  );
}

export function useCanGoBack(): boolean {
  let location = useLocation();
  return !!(location.key && location.key !== "default");
}
