import { ReactNode } from "react";
import { useDrop } from "react-dnd";
import { DisplayedTile } from "../types/displayedTile";
import { DragTypes } from "../types/dragTypes";

export default function TileDroppable({
  children,
  onDrop,
}: {
  children: (dropping: boolean) => ReactNode;
  onDrop: (item: DisplayedTile) => void;
}) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: DragTypes.tile,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return <div ref={drop}>{children(isOver)}</div>;
}
