import styled, { useTheme } from "styled-components";
import { colorForState } from "../../puzzle/board/CharacterTile";
import PuzzlePointsRow from "../../puzzle/game/PuzzlePointsRow";
import ScoreSummary from "../components/ScoreSummary";
import ExampleGrid from "./ExampleGrid";
import { TipCell, TipContent, TipHeader, TipRow } from "./TipComponents";

const ScoreWrapper = styled.div`
  margin-top: 20px;
`;

const PointsSummaryWrapper = styled.div`
  padding: 20px 30px;
`;

export default function Tip3() {
  const board = "XXX XXX XXX XXX XXX XXX";
  const theme = useTheme();
  const aspectRatio = 0.523;
  return (
    <div>
      <TipHeader>Bonuses for longer words</TipHeader>
      <TipContent>
        <TipRow>
          <TipCell>
            <ExampleGrid
              aspectRatio={aspectRatio}
              boardStr={board}
              state="ready"
              tilesStr="plants"
              tileIndices={["1,0", "1,1", "1,2", "1,3", "2,2", "0,2"]}
            />
            <PointsSummaryWrapper>
              <PuzzlePointsRow
                color={colorForState("ready", theme)}
                title="Words"
                value="9"
              ></PuzzlePointsRow>
              <PuzzlePointsRow
                color={colorForState("ready", theme)}
                title="Bonuses"
                value="1"
              ></PuzzlePointsRow>
              <ScoreWrapper>
                <ScoreSummary color={colorForState("ready", theme)}>
                  {"10"}
                </ScoreSummary>
              </ScoreWrapper>
            </PointsSummaryWrapper>
          </TipCell>
          <TipCell>
            <ExampleGrid
              aspectRatio={aspectRatio}
              boardStr={board}
              state="complete"
              tilesStr="plants"
              tileIndices={["1,0", "1,1", "1,2", "1,3", "1,4", "1,5"]}
            />
            <PointsSummaryWrapper>
              <PuzzlePointsRow
                color={colorForState("complete", theme)}
                title="Words"
                value="8"
              ></PuzzlePointsRow>
              <PuzzlePointsRow
                color={colorForState("complete", theme)}
                title="Bonuses"
                value="10"
              ></PuzzlePointsRow>
              <ScoreWrapper>
                <ScoreSummary color={colorForState("complete", theme)}>
                  {"18"}
                </ScoreSummary>
              </ScoreWrapper>
            </PointsSummaryWrapper>
          </TipCell>
        </TipRow>
      </TipContent>
    </div>
  );
}
