export default function subscribeToKeyPress(
  callback: (key: string) => void
): () => void {
  const handler: (this: Window, ev: KeyboardEvent) => any = (e) => {
    if (e.eventPhase === 3) {
      callback(e.key);
    }
  };
  window.addEventListener("keyup", handler);
  return () => window.removeEventListener("keyup", handler);
}
