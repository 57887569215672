import styled from "styled-components";
import { buildBoard, buildTiles } from "../../puzzle/selectors/puzzleSelectors";
import {
  DisplayedTile,
  DisplayedTileState,
} from "../../puzzle/types/displayedTile";
import ViewOnlyPuzzleBoard from "../ViewOnlyPuzzleBoard";

const CellBoard = styled.div<{ aspectRatio?: number }>`
  aspect-ratio: ${(p) => p.aspectRatio || 1};
  flex: 1;
  display: flex;
`;

export default function ExampleGrid({
  state,
  boardStr,
  tilesStr,
  tileIndices,
  aspectRatio,
}: {
  aspectRatio?: number;
  tileIndices: string[];
  boardStr: string;
  tilesStr: string;
  state: DisplayedTileState;
}) {
  const board = buildBoard(boardStr);
  const tiles = buildTiles(tilesStr);
  let tilesByIndex: { [index: string]: DisplayedTile } = {};
  tileIndices.forEach((value, index) => {
    tilesByIndex[tileIndices[index]] = { state, tile: tiles[index] };
  });

  return (
    <CellBoard aspectRatio={aspectRatio}>
      <ViewOnlyPuzzleBoard
        uniqueId="tip1-1"
        board={board}
        tilesByIndex={tilesByIndex}
      />
    </CellBoard>
  );
}
