import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import useShowTutorialOnLaunch from "../../global/hooks/useShowTutorialOnLaunch";
import { todayKey } from "../dailyQueue/selectors/dailyQueueSelectors";
import useOnPageVisible from "../puzzle/hooks/useOnPageVisible";
import { puzzleForDay } from "../puzzle/selectors/puzzleSelectors";
import PuzzlePage from "./PuzzlePage";

export default function PuzzleGameDaily() {
  const today = todayKey();
  const [puzzleDayKey, setPuzzleDayKey] = useState(today);
  const puzzle = useRecoilValue(puzzleForDay(today));
  useShowTutorialOnLaunch();

  useOnPageVisible(() => {
    if (puzzleDayKey !== todayKey()) {
      setPuzzleDayKey(todayKey());
    }
  });

  return <PuzzlePage puzzle={puzzle}></PuzzlePage>;
}
