import { useNavigate } from "react-router";
import styled from "styled-components";
import { useCanGoBack } from "../../global/hooks/useMaybeBackButton";
import AppContainer, { NavTitle } from "../common/AppContainer";
import { RegularText } from "../common/CommonElements";
import { PrimaryButtonLarge } from "../common/PrimaryButton";

const CenterNotFound = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > * + * {
    margin-top: 30px;
  }
`;

export default function NotFound() {
  const nav = useNavigate();
  let canGoBack = useCanGoBack();
  return (
    <AppContainer nav={<NavTitle>GRIDWORD</NavTitle>} fullHeight={true}>
      <CenterNotFound>
        <RegularText>Puzzle not found</RegularText>
        <PrimaryButtonLarge onClick={() => (canGoBack ? nav(-1) : nav("/"))}>
          {canGoBack ? "Back" : "Main Menu"}
        </PrimaryButtonLarge>
      </CenterNotFound>
    </AppContainer>
  );
}
