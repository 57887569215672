import styled from "styled-components";
import { RegularText } from "../../common/CommonElements";

const TipsIntroWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-bottom: 40%;
`;

const IntroTitle = styled(RegularText)`
  text-align: center;
`;

export default function TipsIntro() {
  return (
    <TipsIntroWrapper>
      <IntroTitle>Here are a couple tips to maximize your score!</IntroTitle>
    </TipsIntroWrapper>
  );
}
