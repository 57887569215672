import { Fragment, ReactElement } from "react";
import {
  FaCalendarAlt,
  FaCalendarDay,
  FaLightbulb,
  FaQuestionCircle,
  FaRandom,
  FaSearch,
} from "react-icons/fa";
import { To, useNavigate } from "react-router";
import styled from "styled-components";
import useDocumentTitle from "../../global/hooks/useDocumentTitle";
import {
  useCanGoBack,
  useMaybeBackButton,
} from "../../global/hooks/useMaybeBackButton";
import useShowTutorialOnLaunch from "../../global/hooks/useShowTutorialOnLaunch";
import AppContainer, { NavTitle } from "../common/AppContainer";
import { Spacer } from "../common/CommonElements";
import { SettingsButton } from "./SettingsModal";

type MainMenuItemStyle = "primary" | "secondary" | "tertiary" | "default";

const MainMenuItemWrapper = styled.div<{
  itemStyle: MainMenuItemStyle;
  shape?: "wide" | "square";
}>`
  border-radius: 10px;
  background: ${(p) => {
    switch (p.itemStyle) {
      case "default":
        return p.theme.colors.background.tertiary;
      case "primary":
        return p.theme.colors.primary;
      case "secondary":
        return p.theme.colors.secondary;
      case "tertiary":
        return p.theme.colors.tile.default;
    }
  }};
  flex: 1;
  position: relative;
  aspect-ratio: ${(p) => (p.shape === "wide" ? "" : 1)};
  display: flex;
  flex-direction: ${(p) => (p.shape === "wide" ? "row" : "column")};
  min-height: ${(p) => (p.shape === "wide" ? "80px" : "")};
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  user-select: none;
  text-decoration: none;

  & > * + * {
    margin-left: ${(p) => (p.shape === "wide" ? "10px" : "")};
  }

  &:active {
    opacity: 0.8;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

const MainMenuItemIcon = styled.div<{ itemStyle: MainMenuItemStyle }>`
  font-size: ${(p) => p.theme.text.size.h3}px;
  opacity: ${(p) => (p.itemStyle === "default" ? 1.0 : 0.6)};
  color: ${(p) => {
    switch (p.itemStyle) {
      case "default":
        return p.theme.colors.text.subtle;
      default:
        return p.theme.colors.text.contrast;
    }
  }};
`;

const MainMenuItemTitle = styled.div<{ itemStyle: MainMenuItemStyle }>`
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.r}px;
  font-weight: ${(p) => p.theme.text.weight.bold};
  color: ${(p) =>
    p.itemStyle === "default"
      ? p.theme.colors.text.main
      : p.theme.colors.text.contrast};
`;

function MainMenuItem({
  title,
  icon,
  itemStyle,
  shape,
  onClick,
}: {
  onClick: () => void;
  title: string;
  icon: ReactElement;
  itemStyle: MainMenuItemStyle;
  shape?: "wide" | "square";
}) {
  return (
    <MainMenuItemWrapper itemStyle={itemStyle} shape={shape} onClick={onClick}>
      <MainMenuItemIcon itemStyle={itemStyle}>{icon}</MainMenuItemIcon>
      <MainMenuItemTitle itemStyle={itemStyle}>{title}</MainMenuItemTitle>
    </MainMenuItemWrapper>
  );
}

const MainMenuRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 280px;
  & > * + * {
    margin-left: 16px;
  }
`;

function MainMenuNav() {
  return (
    <Fragment>
      {useMaybeBackButton()}
      <NavTitle>GRIDWORD</NavTitle>

      <Spacer></Spacer>
      <SettingsButton />
    </Fragment>
  );
}

const MainMenuContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > * + * {
    margin-top: 16px;
  }
`;

export default function MainMenu() {
  let _navigate = useNavigate();
  let canGoBack = useCanGoBack();
  const navigate = (to: To, replace?: boolean) => {
    const replacing = replace !== undefined ? replace : canGoBack;
    _navigate(to, {
      replace: replacing,
      state: replacing ? "REPLACED-MAIN" : "FROM-MAIN",
    });
  };

  useDocumentTitle("GridWord");
  useShowTutorialOnLaunch();

  return (
    <AppContainer fullHeight={true} nav={<MainMenuNav />}>
      <MainMenuContainer>
        <MainMenuRow>
          <MainMenuItem
            onClick={() => navigate("/game")}
            title="Daily Puzzle"
            icon={<FaCalendarDay />}
            itemStyle="primary"
          ></MainMenuItem>
          <MainMenuItem
            onClick={() => navigate(`/random`)}
            title="Random"
            icon={<FaRandom />}
            itemStyle="secondary"
          ></MainMenuItem>
        </MainMenuRow>
        <MainMenuRow>
          <MainMenuItem
            onClick={() => navigate("/archive")}
            title="Archive"
            icon={<FaCalendarAlt />}
            itemStyle="default"
          ></MainMenuItem>
          <MainMenuItem
            onClick={() => {
              navigate("/open");
            }}
            title="Open Puzzle"
            icon={<FaSearch />}
            itemStyle="default"
          ></MainMenuItem>
        </MainMenuRow>
        {/* <MainMenuRow>
          <MainMenuItem
            onClick={() => {}}
            shape="wide"
            title="Recent Puzzles"
            icon={<FaHistory />}
            itemStyle="default"
          ></MainMenuItem>
        </MainMenuRow> */}
        <MainMenuRow>
          <MainMenuItem
            onClick={() => {
              navigate("/tutorial", false);
            }}
            shape="wide"
            title="How to play"
            icon={<FaQuestionCircle />}
            itemStyle="tertiary"
          ></MainMenuItem>
        </MainMenuRow>
        <MainMenuRow>
          <MainMenuItem
            onClick={() => {
              navigate("/tips", false);
            }}
            shape="wide"
            title="Tips & Scoring"
            icon={<FaLightbulb />}
            itemStyle="tertiary"
          ></MainMenuItem>
        </MainMenuRow>
      </MainMenuContainer>
    </AppContainer>
  );
}
