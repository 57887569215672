import styled from "styled-components";
import { RegularText } from "../../common/CommonElements";

const TipHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 30px 30px;

  & > * + * {
    margin-top: 6px;
  }
`;

export function TipHeader({ children }: { children: string }) {
  return (
    <TipHeaderWrapper>
      <RegularText>{children}</RegularText>
    </TipHeaderWrapper>
  );
}

export const TipContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
`;

export const TipRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const TipCell = styled.div`
  flex: 1;
  /* aspect-ratio: 0.9; */
  max-width: 170px;
  /* display: flex;
  flex-direction: column;
  justify-content: end; */
`;
