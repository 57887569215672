import React from "react";
import AppContainer from "../common/AppContainer";
import { NoScroll } from "../common/GlobalStyles";
import PuzzleGame from "../puzzle/game/PuzzleGame";
import { PuzzleModel } from "../puzzle/selectors/puzzleSelectors";
import PuzzleStateWrapper from "../puzzle/stateWrapper/PuzzleStateWrapper";
import PuzzleNavBar from "./PuzzleNavBar";

export default function PuzzlePage({ puzzle }: { puzzle: PuzzleModel }) {
  return (
    <PuzzleStateWrapper puzzle={puzzle}>
      <AppContainer nav={<PuzzleNavBar />}>
        <NoScroll />
        <PuzzleGame></PuzzleGame>
      </AppContainer>
    </PuzzleStateWrapper>
  );
}
