import { useNavigate } from "react-router";
import styled from "styled-components";
import { Header1, RegularText } from "../common/CommonElements";
import { PrimaryButtonLarge } from "../common/PrimaryButton";
import TextButton from "../common/TextButton";
import { buildBoard, buildTiles } from "../puzzle/selectors/puzzleSelectors";
import { DisplayedTile } from "../puzzle/types/displayedTile";
import ViewOnlyPuzzleBoard from "./ViewOnlyPuzzleBoard";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopSection = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.background.secondary};
  align-items: stretch;
`;

const BottomSection = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > * + * {
    margin-top: 20px;
  }
`;

const TopContent = styled.div`
  flex: 1;
  /* max-width: 340px; */
  display: flex;
  align-items: stretch;
  margin: 30px 0;
`;

const Header = styled(Header1)`
  /* margin-bottom: 20px; */
  padding: 0 20px;
`;

const Detail = styled(RegularText)`
  margin-bottom: 20px;
`;

export default function TutorialComplete({ onClose }: { onClose: () => void }) {
  const board = buildBoard("XXXXX XXXXX XXXXX XXXXX");
  const tiles = buildTiles("readysetgo");
  const tilesByIndex: { [index: string]: DisplayedTile } = {
    "0,0": { state: "valid", tile: tiles[0] },
    "1,0": { state: "valid", tile: tiles[1] },
    "2,0": { state: "valid", tile: tiles[2] },
    "3,0": { state: "valid", tile: tiles[3] },
    "4,0": { state: "valid", tile: tiles[4] },
    "0,2": { state: "valid", tile: tiles[5] },
    "1,2": { state: "valid", tile: tiles[6] },
    "2,2": { state: "valid", tile: tiles[7] },
    "3,3": { state: "valid", tile: tiles[8] },
    "4,3": { state: "valid", tile: tiles[9] },
  };

  const navigate = useNavigate();

  return (
    <Wrapper>
      <TopSection>
        <TopContent>
          <ViewOnlyPuzzleBoard
            uniqueId="TUTORIAL-COMPLETE"
            board={board}
            tilesByIndex={tilesByIndex}
          ></ViewOnlyPuzzleBoard>
        </TopContent>
      </TopSection>
      <BottomSection>
        <Header>Tutorial Complete</Header>
        <Detail>It's time to play!</Detail>
        <PrimaryButtonLarge
          onClick={() => navigate("/game", { replace: true })}
        >
          Daily Puzzle
        </PrimaryButtonLarge>
        <TextButton onClick={onClose}>Dismiss</TextButton>
      </BottomSection>
    </Wrapper>
  );
}
