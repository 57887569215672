import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

interface DimensionComponentProps {
  onChange: (dimensions: ViewSize) => void;
  buffer?: number;
}

export interface ViewSize {
  width: number;
  height: number;
}

const DimensionWrapper = styled.div`
  z-index: -100;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export default function DimensionComponent({
  onChange,
  buffer,
}: DimensionComponentProps) {
  const targetRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const [dimensions, setDimensions] = useState<ViewSize>({
    width: 0,
    height: 0,
  });

  // holds the timer for setTimeout and clearInterval
  const movement_timer = useRef<number | undefined>();

  // the number of ms the window size must stay the same size before the
  // dimension state variable is reset
  const RESET_TIMEOUT = buffer || 300;

  const test_dimensions = () => {
    // For some reason targetRef.current.getBoundingClientRect was not available
    // I found this worked for me, but unfortunately I can't find the
    // documentation to explain this experience
    if (targetRef.current) {
      const newDimensions = {
        width: (targetRef.current as any).offsetWidth || 0,
        height: (targetRef.current as any).offsetHeight || 0,
      };
      if (
        newDimensions.height !== dimensions.height ||
        newDimensions.width !== dimensions.width
      ) {
        setDimensions(newDimensions);
        onChange(newDimensions);
      }
    }
  };

  // This sets the dimensions on the first render
  useLayoutEffect(() => {
    test_dimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // every time the window is resized, the timer is cleared and set again
    // the net effect is the component will only reset after the window size
    // is at rest for the duration set in RESET_TIMEOUT.  This prevents rapid
    // redrawing of the component for more complex components such as charts
    const listener = () => {
      clearInterval(movement_timer.current);
      movement_timer.current = window.setTimeout(
        test_dimensions,
        RESET_TIMEOUT
      );
    };
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  });

  return <DimensionWrapper ref={targetRef}></DimensionWrapper>;
}
