import { ReactNode } from "react";
import { useDrag } from "react-dnd";
import { DisplayedTile } from "../types/displayedTile";
import { DragTypes } from "../types/dragTypes";

export default function TileDraggable({
  tile,
  children,
}: {
  children: (dragging: boolean) => ReactNode;
  tile: DisplayedTile;
}) {
  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: DragTypes.tile,
      item: tile,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [tile]
  );
  return <div ref={dragRef}>{children(isDragging)}</div>;
}
