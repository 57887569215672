import { ReactNode } from "react";
import { FaArrowLeft } from "react-icons/fa";
import styled from "styled-components";
import { IconButton } from "../../common/CommonElements";

const FooterWrapper = styled.div`
  /* height: 80px; */
`;
const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 30px 30px;
`;

export default function TutorialFooter({
  onBack,
  children,
}: {
  onBack: () => void;
  children: ReactNode;
}) {
  return (
    <FooterWrapper>
      <FooterContent>
        <IconButton onClick={onBack}>
          <FaArrowLeft />
        </IconButton>
        {children}
      </FooterContent>
    </FooterWrapper>
  );
}
