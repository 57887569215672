import styled, { DefaultTheme } from "styled-components";
import { DisplayedTile, DisplayedTileState } from "../types/displayedTile";

export function colorForState(state: DisplayedTileState, theme: DefaultTheme) {
  switch (state) {
    case "default":
      return theme.colors.tile.default;
    case "valid":
      return theme.colors.tile.valid;
    case "ready":
      return theme.colors.tile.ready;
    case "complete":
      return theme.colors.tile.submitted;
  }
}

const TileDiv = styled.div<{
  semiOpaque?: boolean;
  valid?: boolean;
  state: DisplayedTileState;
  size: number;
}>`
  user-select: none;
  opacity: ${(p) => (p.semiOpaque ? 0.3 : 1)};
  background: ${(p) => colorForState(p.state, p.theme)};
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  /* width: 44px;
  height: 44px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.tile.foreground};
  font-weight: 600;
  font-size: ${(p) => Math.floor(p.size * 0.55)}px;
  /* font-size: 24px; */
  border-radius: 3px;
  position: relative;
  font-family: ${(p) => p.theme.text.family.primary};
`;

const TilePointsDiv = styled.div<{ tileSize: number }>`
  font-size: ${(p) => Math.floor(0.23 * p.tileSize)}px;
  right: 0;
  bottom: 0;
  width: 36%;
  height: 36%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

export default function CharacterTile({
  tile,
  semiOpaque,
  size,
}: {
  tile: DisplayedTile;
  semiOpaque?: boolean;
  size: number;
}) {
  return (
    <TileDiv state={tile.state} semiOpaque={semiOpaque} size={size}>
      {tile.tile.character}
      <TilePointsDiv tileSize={size}>{tile.tile.points}</TilePointsDiv>
    </TileDiv>
  );
}
