import { selector } from "recoil";
import { darkModeSelector } from "../../../global/selectors/DarkModeAtom";
import makeArray from "../../../global/utils/makeArray";
import { puzzleScore } from "./puzzleScoreSelectors";
import { puzzleAtom, puzzleTitleShort } from "./puzzleSelectors";
import { tilesByIndex } from "./puzzleStateSelectors";
import { indexToKey } from "./userPuzzleSelectors";

export const puzzleCopyText = selector<string>({
  key: "puzzleCopyText",
  get: ({ get }) => {
    const darkMode = get(darkModeSelector);
    const space = "🟦";
    const tile = "🟩";
    const bgLight = "⬜";
    const bgDark = "⬛";
    const bg = darkMode ? bgDark : bgLight;
    const puzzle = get(puzzleAtom);
    const tiles = get(tilesByIndex);
    const title = get(puzzleTitleShort);
    const rows = puzzle.board.map((row, y) => {
      const cells = row.map((cell, x) => {
        if (!cell) return bg;
        if (tiles[indexToKey({ x, y })]) return tile;
        return space;
      });
      return [bg, ...cells, bg].join("");
    });
    const bgRow = makeArray(puzzle.board[0].length + 2)
      .map(() => bg)
      .join("");
    const finalBoard = [bgRow, ...rows, bgRow].join("\n");
    const finalScore = get(puzzleScore);
    return `playgridword.com ${title} ${finalScore}\n${finalBoard}`;
  },
});
