import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { PrimaryButton } from "../../common/PrimaryButton";
import { useShareText } from "../hooks/useShareText";
import {
  bonusScore,
  puzzleScore,
  unusedLettersScore,
  wordScore,
} from "../selectors/puzzleScoreSelectors";
import { puzzleTitle } from "../selectors/puzzleSelectors";
import { puzzleCopyText } from "../selectors/puzzleShareSelectors";
import PuzzlePointsRow from "./PuzzlePointsRow";

const ScoreWrapper = styled.div`
  border: 2px solid ${(p) => p.theme.colors.background.board};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin-right: 30px;
  min-width: 90px;
`;
const PuzzleNumber = styled.div`
  font-size: ${(p) => p.theme.text.size.m}px;
  font-weight: 700;
  color: ${(p) => p.theme.colors.text.subtle};
  text-transform: uppercase;
`;
const ScoreNumber = styled.div`
  font-size: ${(p) => p.theme.text.size.h1}px;
  font-weight: 800;
  color: ${(p) => p.theme.colors.text.main};
`;
const ScoreTitle = styled.div`
  font-size: ${(p) => p.theme.text.size.m}px;
  font-weight: 700;
  color: ${(p) => p.theme.colors.text.main};
  text-transform: uppercase;
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: ${(p) => p.theme.colors.background.main};
`;

const ShareWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const PuzzlePoints = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  min-width: 140px;
`;

export default function Results({ disableShare }: { disableShare?: boolean }) {
  const [copied, setCopied] = useState(false);
  const finalScore = useRecoilValue(puzzleScore);
  const text = useRecoilValue(puzzleCopyText);
  const words = useRecoilValue(wordScore);
  const bonuses = useRecoilValue(bonusScore);
  const unused = useRecoilValue(unusedLettersScore);
  const title = useRecoilValue(puzzleTitle);
  const share = useShareText(text, () => setCopied(true));

  return (
    <ResultsWrapper>
      <ScoreWrapper>
        <PuzzleNumber>{title}</PuzzleNumber>
        <ScoreNumber>{finalScore}</ScoreNumber>
        <ScoreTitle>Score</ScoreTitle>
      </ScoreWrapper>
      <ShareWrapper>
        <PuzzlePoints>
          <PuzzlePointsRow title="Words" value={`${words}`} />
          <PuzzlePointsRow title="Bonuses" value={`${bonuses}`} />
          <PuzzlePointsRow title="Unused" value={`${unused}`} />
        </PuzzlePoints>
        <PrimaryButton disabled={disableShare} onClick={share}>
          {copied ? "Copied!" : "Share"}
        </PrimaryButton>
      </ShareWrapper>
    </ResultsWrapper>
  );
}
