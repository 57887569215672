import styled from "styled-components";
import { Subtitle } from "../../common/CommonElements";

const PuzzlePointsRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;
const PuzzlePointsTitle = styled(Subtitle)<{ color?: string }>`
  color: ${(p) => p.color};
`;

const PuzzlePointsValue = styled.div<{ color?: string }>`
  font-size: ${(p) => p.theme.text.size.m}px;
  font-weight: 700;
  color: ${(p) => p.color || p.theme.colors.text.main};
  text-transform: uppercase;
  min-width: 30px;
  text-align: center;
`;

export default function PuzzlePointsRow({
  title,
  value,
  color,
}: {
  title: string;
  value: string;
  color?: string;
}) {
  return (
    <PuzzlePointsRowWrapper>
      <PuzzlePointsTitle color={color}>{title}</PuzzlePointsTitle>
      <PuzzlePointsValue color={color}>{value}</PuzzlePointsValue>
    </PuzzlePointsRowWrapper>
  );
}
