import { UserPuzzle } from "../selectors/userPuzzleSelectors";

export default function moveToEndOfRack(
  tileId: string,
  current: UserPuzzle
): UserPuzzle {
  const rackIndex: number =
    Math.max(...Object.values(current.map).map((i) => i.rackIndex || 0)) + 1;
  return {
    ...current,
    map: {
      ...current.map,
      [tileId]: { rackIndex },
    },
  };
}
