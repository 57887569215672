import { AnimatePresence, motion, Variants } from "framer-motion";
import { Fragment, ReactNode, useState } from "react";
import styled from "styled-components";

const Page = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

type AnimateDirection = "right" | "left";

const PagesDiv = styled.div`
  flex: 1;
  position: relative;
`;

export function Pages({
  children,
}: {
  children: (previous: () => void, next: () => void) => ReactNode[];
}) {
  return (
    <PagesWrapper>
      {(page, direction, previous, next) => (
        <PagesContent page={page} direction={direction} key="PagesContent">
          {children(previous, next)}
        </PagesContent>
      )}
    </PagesWrapper>
  );
}

export function PagesWrapper({
  children,
}: {
  children: (
    current: number,
    direction: AnimateDirection,
    previous: () => void,
    next: () => void
  ) => ReactNode;
}) {
  const [page, setPage] = useState(0);
  const [direction, setDirection] = useState<AnimateDirection>("left");
  const animateFn: (direction: AnimateDirection) => () => void =
    (direction) => () => {
      const newPage = page + (direction === "left" ? 1 : -1);
      setDirection(direction);
      setPage(newPage);
    };
  return (
    <Fragment>
      {children(page, direction, animateFn("right"), animateFn("left"))}
    </Fragment>
  );
}

export function PagesContent({
  page,
  direction,
  children,
}: {
  page: number;
  direction: AnimateDirection;
  children: ReactNode[];
}) {
  const xOffset = 100;
  const variants: Variants = {
    enter: (direction) => ({
      x: direction === "left" ? xOffset : -xOffset,
      opacity: 0,
    }),
    active: {
      x: 0,
      opacity: 1,
      //   transition: { delay: 0.2 },
    },
    leave: (direction) => ({
      x: direction === "left" ? -xOffset : xOffset,
      opacity: 0,
    }),
  };

  return (
    <PagesDiv>
      <AnimatePresence custom={direction}>
        {children.flatMap((child, i) => {
          if (i !== page) return [];
          return [
            <Page
              key={`page-${i}`}
              variants={variants}
              initial="enter"
              animate="active"
              exit="leave"
              custom={direction}
              transition={{ ease: "easeOut", duration: 0.25 }}
            >
              {child}
            </Page>,
          ];
        })}
      </AnimatePresence>
    </PagesDiv>
  );
}
