import { DefaultTheme } from "styled-components";

export const lightModeTheme: DefaultTheme = {
  colors: {
    primary: "#13b249",
    primaryAlt: "#289c4f",
    secondary: "#4868DA",
    secondaryAlt: "#3553c0",
    selection: "#FF1DB2",
    button: {
      default: "#4868DA",
      disabled: "#caced8",
      warning: "#CC8633",
    },
    tile: {
      default: "#5f6885",
      valid: "#009CCD",
      ready: "#4772e7",
      submitted: "#13b249",
      foreground: "#fff",
    },
    background: {
      main: "#fff",
      secondary: "#D9DCE3",
      tertiary: "#fff",
      board: "#D9DCE3",
      tileDefault: "#b6bdce",
      tileHighlighted: "#889cd7",
      modalOverlay: "#cccccccc",
    },
    text: {
      main: "#3a3a3a",
      subtle: "#959cae",
      contrast: "#ffffff",
      disabled: "#90939e",
    },
  },
  text: {
    weight: {
      bold: 700,
      medium: 600,
      regular: 500,
    },
    family: {
      primary: "Poppins",
      secondary: "Send Flowers",
    },
    size: {
      h1: 48,
      h2: 38,
      h3: 32,
      xl: 26,
      l: 20,
      m: 16,
      r: 14,
      s: 13,
    },
  },
};

export const darkModeTheme: DefaultTheme = {
  colors: {
    primary: "#1cb34e",
    primaryAlt: "#289c4f",
    secondary: "#3C64F1",
    secondaryAlt: "#3553c0",
    selection: "#ffffff",
    button: {
      default: "#4868DA",
      disabled: "#41444c",
      warning: "#CC8633",
    },
    tile: {
      default: "#808bae",
      valid: "#0E98C3",
      ready: "#3C64F1",
      submitted: "#1cb34e",
      foreground: "#fff",
    },
    background: {
      main: "#1a1a1a",
      board: "#242528",
      tileDefault: "#353946",
      tileHighlighted: "#404965",
      secondary: "#242528",
      tertiary: "#353945",
      modalOverlay: "#30303099",
    },
    text: {
      main: "#ffffff",
      subtle: "#828BAA",
      contrast: "#ffffff",
      disabled: "#80838e",
    },
  },
  text: {
    weight: {
      bold: 700,
      medium: 600,
      regular: 500,
    },
    family: {
      primary: "Poppins",
      secondary: "Send Flowers",
    },
    size: {
      h1: 48,
      h2: 38,
      h3: 32,
      xl: 26,
      l: 20,
      m: 16,
      r: 14,
      s: 13,
    },
  },
};
