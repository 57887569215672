import React, { ReactNode } from "react";
import useTileTapped from "../../hooks/useTileTapped";
import { TileModel } from "../../selectors/puzzleSelectors";

export default function TappableTile({
  tile,
  children,
}: {
  tile: TileModel;
  children: ReactNode;
}) {
  const tileTapped = useTileTapped();

  return (
    <div
      onClick={() => {
        tileTapped(tile);
      }}
    >
      {children}
    </div>
  );
}
