import { selector } from "recoil";
import { puzzleAtom, TileModel } from "./puzzleSelectors";
import {
  indexToKey,
  TileState,
  userPuzzleSelector,
} from "./userPuzzleSelectors";

export type BoardStateMap = { [puzzleIndex: string]: TileModel | "empty" };

export const boardState = selector<BoardStateMap>({
  key: "boardState",
  get: ({ get }) => {
    const puzzle = get(puzzleAtom);
    const board = puzzle.board;
    const tiles = get(tilesByIndex);
    let locations: { [location: string]: TileModel | "empty" } = {};
    board.forEach((row, y) => {
      row.forEach((cell, x) => {
        if (!cell) return;
        const location = indexToKey({ x, y });
        locations[location] = tiles[location] || "empty";
      });
    });
    return locations;
  },
});

type TileStateItem = { id: string; state: TileState };
export const tileStateArray = selector<TileStateItem[]>({
  key: "tileStateArray",
  get: async ({ get }) => {
    const puzzle = get(puzzleAtom);
    const states = get(userPuzzleSelector).map;
    var maxRackIndex = 0;
    let output = puzzle.tiles.map((t) => {
      const k = t.id;
      const state = states[k];
      if (state && state.rackIndex !== undefined)
        maxRackIndex = Math.max(maxRackIndex, state.rackIndex);
      return { id: k, state: state };
    });
    return output
      .sort((a, b) => {
        return a.id.localeCompare(b.id);
      })
      .map((tileState) => ({
        ...tileState,
        state: tileState.state || { rackIndex: maxRackIndex++ },
      }));
  },
});

export const tilesById = selector<{ [id: string]: TileModel }>({
  key: "tilesById",
  get: async ({ get }) => {
    const tiles = get(puzzleAtom).tiles;
    const arr = tiles.map((t) => ({ [t.id]: t }));
    return Object.assign({}, ...arr);
  },
});

type TileMap = { [key: string]: TileModel };
export const tilesByIndex = selector<TileMap>({
  key: "tilesByboardIndex",
  get: async ({ get }) => {
    const states = get(tileStateArray);
    const byId = get(tilesById);
    const arr: TileMap[] = states.flatMap((state) => {
      const location = state.state.boardIndex;
      if (!location) return [];
      return [{ [indexToKey(location)]: byId[state.id] }];
    });
    return Object.assign({}, ...arr);
  },
});

export const tileRack = selector<TileModel[]>({
  key: "tileRack",
  get: ({ get }) => {
    const states = get(tileStateArray);
    const byId = get(tilesById);
    return states
      .filter((item) => item.state.rackIndex !== undefined)
      .sort((a, b) => (a.state.rackIndex || 0) - (b.state.rackIndex || 0))
      .map((item) => byId[item.id]);
  },
});
