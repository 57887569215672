function buildNumberShortener(
  charset: string,
  postEncode: (output: string) => string,
  preDecode: (input: string) => string
) {
  const splitCharset = charset.split("");
  return {
    encode: (integer: number) => {
      if (integer === 0) {
        return postEncode("0");
      }
      let s: string[] = [];
      while (integer > 0) {
        s = [splitCharset[integer % charset.length], ...s];
        integer = Math.floor(integer / charset.length);
      }
      return postEncode(s.join(""));
    },
    decode: (chars: string) =>
      preDecode(chars)
        .split("")
        .reverse()
        .reduce(
          (prev, curr, i) =>
            prev + splitCharset.indexOf(curr) * charset.length ** i,
          0
        ),
  };
}

export const chars61 = "0123456789abcdefghijklmnoprstuvwxyz";

export function validChars61(char: string): boolean {
  const all = chars61 + "q";
  return all.includes(char);
}

export const base61Four = buildNumberShortener(
  chars61,
  (encoded) => encoded + "q".repeat(Math.max(4 - encoded.length, 0)),
  (characters) => characters.replaceAll(/q/g, "").toLowerCase()
);

export const base61Three = buildNumberShortener(
  chars61,
  (encoded) => encoded + "q".repeat(Math.max(3 - encoded.length, 0)),
  (characters) => characters.replaceAll(/q/g, "").toLowerCase()
);

export function randomTileset(): string {
  const maxTileset = 10000;
  return base61Three.encode(randomNumberBetween(0, maxTileset));
}

export function randomBoard(): string {
  // const maxBoard = 100000;
  const maxBoard = 8000;
  return base61Four.encode(randomNumberBetween(0, maxBoard));
}

function randomNumberBetween(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function randomPuzzleTag(): string {
  return `${randomTileset()}-${randomBoard()}`;
}
