import { ReactNode } from "react";
import styled from "styled-components";
import { BodyFullHeight, ThemedBackground } from "./GlobalStyles";
import Loading from "./Loading";

const Wrapper = styled.div`
  /* background-color: ${(p) => p.theme.colors.background.board}; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex: 1;
  /* min-height: 100vh;
  min-height: -webkit-fill-available; */
  position: relative;
  padding-top: 50px;
`;

const Content = styled.div<{ justifyContent?: string; alignItems?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => p.justifyContent || "center"};
  align-items: ${(p) => p.alignItems || "stretch"};
  flex: 1;
`;

const NavigationBarWrapper = styled.div`
  height: 50px;
  background: ${(p) => p.theme.colors.background.main};
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 18px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
`;

export default function AppContainer({
  nav,
  children,
  onClick,
  justifyContent,
  alignItems,
  fullHeight,
}: {
  nav: ReactNode;
  children: ReactNode;
  onClick?: () => void;
  justifyContent?: string;
  alignItems?: string;
  fullHeight?: boolean;
}) {
  return (
    <Wrapper>
      {fullHeight ? <BodyFullHeight /> : undefined}
      <ThemedBackground />
      <NavigationBarWrapper>{nav}</NavigationBarWrapper>
      <Content
        onClick={onClick}
        alignItems={alignItems}
        justifyContent={justifyContent}
      >
        {children}
      </Content>
    </Wrapper>
  );
}

export const NavCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
`;

export const NavTitle = styled(NavCentered)`
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.l}px;
  color: ${(p) => p.theme.colors.text.main};
  font-weight: 800;
`;

export function AppLoading() {
  return (
    <AppContainer nav={<NavTitle>GRIDWORD</NavTitle>}>
      <Loading fullHeight={true}></Loading>
    </AppContainer>
  );
}
