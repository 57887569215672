import { atom, selector } from "recoil";
import { BoardModel, puzzleAtom, TileModel } from "./puzzleSelectors";
import { boardState } from "./puzzleStateSelectors";
import { indexToKey, PuzzleIndex } from "./userPuzzleSelectors";

export function firstValidIndex(board: BoardModel): PuzzleIndex {
  for (var y = 0; y < board.length; y++) {
    for (var x = 0; x < board[y].length; x++) {
      const item = board[y][x];
      if (item) return { x, y };
    }
  }
  return { x: 1, y: 1 };
}

// export const puzzleSelectionAtom = atomFamily<PuzzleIndex | undefined, string>({
//   key: "puzzleSelection",
//   default: undefined,
// });

export const selectedIndexAtom = atom<PuzzleIndex>({
  key: "selectedIndexAtom",
  default: selector<PuzzleIndex>({
    key: "defaultSelectedboardIndex",
    get: ({ get }) => {
      const curBoard = get(puzzleAtom).board;
      return firstValidIndex(curBoard);
    },
  }),
});

export type SelectionDirection = "down" | "right";
export const selectedDirectionAtom = atom<SelectionDirection>({
  key: "selectedDirectionAtom",
  default: "down",
});

export const nextboardIndex = selector<PuzzleIndex | undefined>({
  key: "nextboardIndex",
  get: ({ get }) => {
    const current = get(selectedIndexAtom);
    const direction = get(selectedDirectionAtom);
    const board = get(boardState);

    const dx = direction === "down" ? 0 : 1;
    const dy = direction === "right" ? 0 : 1;

    const loc = locationShifted(current, dx, dy);
    let slot = board[indexToKey(loc)];
    return slot === undefined ? undefined : loc;
  },
});

export const previousboardIndex = selector<PuzzleIndex | undefined>({
  key: "previousboardIndex",
  get: ({ get }) => {
    const current = get(selectedIndexAtom);
    const direction = get(selectedDirectionAtom);
    const board = get(boardState);

    const dx = direction === "down" ? 0 : -1;
    const dy = direction === "right" ? 0 : -1;

    const loc = locationShifted(current, dx, dy);
    let slot = board[indexToKey(loc)];
    return slot === undefined ? undefined : loc;
  },
});

export const selectedLocationTileSelector = selector<TileModel | undefined>({
  key: "selectedLocationTile",
  get: ({ get }) => {
    const loc = get(selectedIndexAtom);
    const tiles = get(boardState);
    const tile = tiles[indexToKey(loc)];
    if (tile === "empty") return undefined;
    return tile;
  },
});

function locationShifted(
  location: PuzzleIndex,
  dx: number,
  dy: number
): PuzzleIndex {
  return {
    x: location.x + dx,
    y: location.y + dy,
  };
}
