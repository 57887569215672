import { addDays, differenceInDays, format, parse, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { atom, DefaultValue, selectorFamily } from "recoil";
import { db } from "../../../global/Firebase";
import buildRawConverter from "../../../global/utils/buildRawConverter";
import makeArray from "../../../global/utils/makeArray";
import {
  buildBoard,
  buildTiles,
  puzzleForDay,
  PuzzleModel,
} from "../../puzzle/selectors/puzzleSelectors";

interface DailyQueue {
  lastCompletedDay: string;
  lastCompletedIndex: number;
  queue: string[];
}

export const dailyQueueAtom = atom<DailyQueue | undefined>({
  key: "dailyQueueAtom",
  //   default: selector<DailyQueue | undefined>({
  //     key: "dailyQueueDefaultSelector",
  //     get: async () => {
  //       const converter = buildRawConverter<DailyQueue>();
  //       const ref = doc(db, "queues/daily").withConverter(converter);
  //       return (await getDoc(ref)).data();
  //     },
  //   }),
  effects: [
    ({ setSelf }) => {
      const converter = buildRawConverter<DailyQueue>();
      const ref = doc(db, "queues/daily").withConverter(converter);
      return onSnapshot(ref, {
        next: (snapshot) => {
          setSelf(snapshot.data());
        },
        error: (error) => {
          console.log("DQ error", error);
        },
      });
    },
    ({ onSet }) => {
      onSet((newValue, oldValue) => {
        if (
          !newValue ||
          !oldValue ||
          oldValue instanceof DefaultValue ||
          newValue.queue.length === oldValue.queue.length
        ) {
          return;
        }
        const converter = buildRawConverter<DailyQueue>();
        const ref = doc(db, "queues/daily").withConverter(converter);
        setDoc(ref, newValue);
      });
    },
  ],
});

interface BoardBankItem {
  board: string;
  columns: number;
  rows: number;
  squares: number;
  index: number;
  key: string;
}

interface TileBankItem {
  index: number;
  key: string;
  length: number;
  tiles: string;
}

export function keyToLocalDate(key: string): Date {
  return parse(key, "yyyy-MM-dd", new Date());
}

export function keyToDate(key: string): Date {
  return parseISO(`${key}T00:00:00Z`);
}

export function localDateToKey(date: Date): string {
  return format(date, "yyyy-MM-dd");
}

export function dateToKey(date: Date): string {
  return formatInTimeZone(date, "GMT", "yyyy-MM-dd");
}

export function vanillaDate(date: Date) {
  return keyToDate(dateToKey(date));
}

export function todayKey(): string {
  return localDateToKey(new Date());
}

export const boardsOfIds = selectorFamily<BoardBankItem[], string[]>({
  key: "boardBankSetSelector",
  get:
    (ids) =>
    async ({ get }) => {
      const bConverter = buildRawConverter<BoardBankItem>();
      const boardBank = collection(db, "boardBank").withConverter(bConverter);
      const boardQuery = query(boardBank, where("key", "in", ids));
      const boards = (await getDocs(boardQuery)).docs.map((i) => i.data());
      const boardsMap: { [key: string]: BoardBankItem } = boards.reduce(
        (p, c) => ({ ...p, [c.key]: c }),
        {}
      );
      return ids.flatMap((id) => boardsMap[id]);
    },
});

export const tilesetsOfIds = selectorFamily<TileBankItem[], string[]>({
  key: "tilesetBankSetSelector",
  get:
    (ids) =>
    async ({ get }) => {
      const tConverter = buildRawConverter<TileBankItem>();
      const tileBank = collection(db, "tileBank").withConverter(tConverter);
      const tilesetQuery = query(tileBank, where("key", "in", ids));
      const tilesets = (await getDocs(tilesetQuery)).docs.map((i) => i.data());
      const tilesetMap: { [key: string]: TileBankItem } = tilesets.reduce(
        (p, c) => ({ ...p, [c.key]: c }),
        {}
      );
      return ids.map((id) => tilesetMap[id]);
    },
});

interface PuzzleQueuePage {
  puzzles: PuzzleModel[];
  alreadyCreatedCount: number;
  queueStartIndex: number;
}

export const puzzleQueuePageSelector = selectorFamily<PuzzleQueuePage, number>({
  key: "puzzleQueuePageSelector",
  get:
    (page) =>
    async ({ get }) => {
      const queue = get(dailyQueueAtom);
      if (!queue)
        return { puzzles: [], alreadyCreatedCount: 0, queueStartIndex: 0 };

      const lastCompletedDate = keyToDate(queue.lastCompletedDay);
      const tomorrowKey = localDateToKey(addDays(new Date(), 1));
      const tomorrow = keyToDate(tomorrowKey);

      const alreadyDoneQueued =
        differenceInDays(lastCompletedDate, tomorrow) + 1;

      const doneToQuery = page === 0 ? alreadyDoneQueued : 0;

      const donePuzzles = makeArray(doneToQuery).map((i) => {
        const daykey = dateToKey(addDays(tomorrow, i));
        return get(puzzleForDay(daykey));
      });

      const pageStartIndex =
        page === 0 ? 0 : 10 - alreadyDoneQueued + 10 * (page - 1);
      const pageLength = page === 0 ? 10 - alreadyDoneQueued : 10;
      const keys = queue.queue
        .slice(pageStartIndex, pageStartIndex + pageLength)
        .map((k) => k.split("-"));
      const boardIds = keys.map((k) => k[1]);
      const tileIds = keys.map((k) => k[0]);
      const boards = get(boardsOfIds(boardIds));
      const tilesets = get(tilesetsOfIds(tileIds));

      const startDate = addDays(lastCompletedDate, 1 + pageStartIndex);
      const startIndex = queue.lastCompletedIndex + 1 + pageStartIndex;
      const queuePuzzles: PuzzleModel[] = keys.flatMap((k, i) => {
        const board = boards[i];
        const tileset = tilesets[i];
        if (!board || !tileset) return [];
        return [
          {
            board: buildBoard(board.board),
            tiles: buildTiles(tileset.tiles),
            source: {
              key: "daily",
              index: startIndex + i,
              day: dateToKey(addDays(startDate, i)),
              tag: k.join("-"),
            },
          },
        ];
      });

      return {
        puzzles: donePuzzles.concat(queuePuzzles),
        alreadyCreatedCount: doneToQuery,
        queueStartIndex: pageStartIndex,
      };
    },
});
