import React from "react";
import { useDrop } from "react-dnd";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import moveToEndOfRack from "../../actions/moveToEndOfRack";
import moveToRackIndex from "../../actions/moveToRackIndex";
import CharacterTile from "../../board/CharacterTile";
import TileDraggable from "../../interactivePuzzle/TileDraggable";
import { useUpdateUserPuzzle } from "../../selectors/userPuzzleHistory";
import { userPuzzleSelector } from "../../selectors/userPuzzleSelectors";
import { DisplayedTile } from "../../types/displayedTile";
import { DragTypes } from "../../types/dragTypes";
import TappableTile from "./TappableTile";

const Cell = styled.div<{ size: number }>`
  padding: 2px;
  width: ${(p) => p.size - 4}px;
  height: ${(p) => p.size - 4}px;
  display: flex;
`;

const CellContent = styled.div`
  flex: 1;
`;

export default function DroppableRackCell({
  tile,
  size,
}: {
  size: number;
  tile?: DisplayedTile;
}) {
  const userPuzzle = useRecoilValue(userPuzzleSelector);
  const updateUserPuzzle = useUpdateUserPuzzle();

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DragTypes.tile,
      drop: (item: DisplayedTile) => {
        if (tile) {
          const currentIndex = userPuzzle.map[tile.tile.id].rackIndex || 0;
          updateUserPuzzle((puzzle) =>
            moveToRackIndex(item.tile.id, currentIndex, puzzle)
          );
        } else {
          updateUserPuzzle((puzzle) => moveToEndOfRack(item.tile.id, puzzle));
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [tile, updateUserPuzzle, userPuzzle]
  );
  return (
    <Cell ref={drop} size={size}>
      <CellContent>
        {tile ? (
          <TappableTile tile={tile.tile}>
            <TileDraggable tile={tile}>
              {(dragging) => (
                <CharacterTile
                  semiOpaque={dragging || isOver}
                  tile={tile}
                  size={size - 3}
                ></CharacterTile>
              )}
            </TileDraggable>
          </TappableTile>
        ) : undefined}
      </CellContent>
    </Cell>
  );
}
