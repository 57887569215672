import { doc, getDoc, QueryDocumentSnapshot } from "firebase/firestore";
import { atom, selector } from "recoil";
import { auth, db } from "../../../global/Firebase";

export interface User {
  id: string;
  isAdmin: boolean;
}

export const userIdAtom = atom<string | undefined>({
  key: "userId",
  default: selector<string | undefined>({
    key: "initialUserId",
    get: () => {
      return auth.currentUser?.uid;
    },
  }),
  effects: [
    ({ setSelf }) => {
      return auth.onAuthStateChanged((user) => {
        setSelf(user?.uid);
      });
    },
  ],
});

const usersMetaConverter = {
  toFirestore: (data: User) => {
    return {};
  },

  fromFirestore: (snap: QueryDocumentSnapshot): User => {
    console.log("is admin", snap.data());
    return { id: snap.id, isAdmin: snap.data().roles.indexOf("admin") !== -1 };
  },
};

export const userSelector = selector<User | undefined>({
  key: "userSelector",
  get: async ({ get }) => {
    const id = get(userIdAtom);
    if (id) {
      try {
        const meta = await getDoc(
          doc(db, "usersMeta/" + id).withConverter(usersMetaConverter)
        );
        const existing = meta.data();
        if (!existing) return { id, isAdmin: false };
        return existing;
      } catch (error) {
        console.log("Error getting user", error);
      }
    }
    return undefined;
  },
});
