import { atom, selector } from "recoil";

export function systemDarkMode() {
  return (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
}

function storedDarkMode(): boolean | undefined {
  if (localStorage.getItem("darkMode") === "1") return true;
  if (localStorage.getItem("darkMode") === "0") return false;
  return undefined;
}

function storeDarkMode(enabled: boolean) {
  localStorage.setItem("darkMode", enabled ? "1" : "0");
}

export const darkModeAtom = atom<boolean | undefined>({
  key: "darkModeAtom",
  default: storedDarkMode(),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (newValue !== undefined) storeDarkMode(newValue);
      });
    },
  ],
});

export const darkModeSelector = selector<boolean>({
  key: "darkModeEnabled",
  get: ({ get }) => {
    const atomValue = get(darkModeAtom);
    if (atomValue === undefined) return systemDarkMode();
    return atomValue;
  },
});
