import { useEffect } from "react";
import { useNavigate } from "react-router";

export default function useShowTutorialOnLaunch() {
  let navigate = useNavigate();
  const hasSeenKey = "hasSeenTutorial";
  useEffect(() => {
    const hasSeen = localStorage.getItem(hasSeenKey);
    if (!hasSeen) {
      navigate("/tutorial");
      localStorage.setItem(hasSeenKey, "seen");
    }
  }, [navigate]);
}
