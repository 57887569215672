import React, { ReactNode } from "react";
import styled from "styled-components";
import { PrimaryButton } from "../common/PrimaryButton";
import GridwordModal from "./GridwordModal";

type TextAlertButtonType = "primary" | "default";

export interface TextAlertButton {
  text: string;
  type?: TextAlertButtonType;
  action?: () => void;
}

const TextAlertContent = styled.div`
  & > * + * {
    margin-top: 10px;
  }
`;

const TextAlertTitle = styled.div`
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.l}px;
  color: ${(p) => p.theme.colors.text.main};
  font-weight: 600;
  text-align: center;
`;

const TextAlertMessage = styled.div`
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.r}px;
  color: ${(p) => p.theme.colors.text.main};
  font-weight: 500;
  text-align: center;
`;

const TextAlertButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  padding-top: 15px;
`;

const TextAlertButtonView = styled(PrimaryButton)<{ primary: boolean }>`
  background-color: ${(p) =>
    p.primary ? p.theme.colors.secondary : p.theme.colors.background.secondary};
  color: ${(p) =>
    p.primary ? p.theme.colors.text.contrast : p.theme.colors.text.main};
  margin: 0 5px;
  flex: 1;

  &:active {
    opacity: 0.8;
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${(p) =>
        p.primary
          ? p.theme.colors.secondaryAlt
          : p.theme.colors.background.board};
      color: ${(p) =>
        p.primary ? p.theme.colors.text.contrast : p.theme.colors.text.main};
    }
  }
`;

export default function TextAlert({
  title,
  message,
  buttons,
  show,
  close,
}: {
  close?: () => void;
  show: boolean;
  title?: string;
  message: ReactNode;
  buttons: TextAlertButton[];
}) {
  return (
    <GridwordModal show={show}>
      <TextAlertContent>
        {title ? <TextAlertTitle>{title}</TextAlertTitle> : undefined}
        <TextAlertMessage>{message}</TextAlertMessage>
        <TextAlertButtonsWrapper>
          {buttons.map((b) => {
            return (
              <TextAlertButtonView
                key={`${message}%%%${b.text}`}
                primary={b.type === "primary"}
                onClick={() => {
                  if (b.action) b.action();
                  if (close) {
                    setTimeout(function () {
                      close();
                    }, 50);
                  }
                }}
              >
                {b.text}
              </TextAlertButtonView>
            );
          })}
        </TextAlertButtonsWrapper>
      </TextAlertContent>
    </GridwordModal>
  );
}
