import { useRecoilValue } from "recoil";
import {
  selectedDirectionAtom,
  selectedIndexAtom,
} from "../selectors/puzzleSelectionSelectors";
import { boardState, tileRack } from "../selectors/puzzleStateSelectors";
import { indexToKey } from "../selectors/userPuzzleSelectors";
import useMoveSelectedIndex from "./useMoveSelectedIndex";
import useTileTapped from "./useTileTapped";

export default function useCharacterTapped(): (character: string) => void {
  const direction = useRecoilValue(selectedDirectionAtom);
  const tileTapped = useTileTapped();
  const board = useRecoilValue(boardState);
  const selected = useRecoilValue(selectedIndexAtom);
  const rack = useRecoilValue(tileRack);
  const moveLocation = useMoveSelectedIndex();
  return (key) => {
    const current = board[indexToKey(selected)];
    if (!current) return;
    if (current !== "empty" && current.character === key) {
      if (direction === "down") {
        moveLocation(0, 1);
      } else {
        moveLocation(1, 0);
      }
    } else {
      const inRack = rack.find((t) => t.character === key.toLowerCase());
      if (inRack) {
        tileTapped(inRack);
      }
    }
  };
}
