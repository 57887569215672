import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import styled from "styled-components";
import { auth } from "../../global/Firebase";
import AppContainer, { NavTitle } from "../common/AppContainer";
import { PrimaryButton } from "../common/PrimaryButton";

const Input = styled.input<{ error?: string }>`
  background-color: ${(p) => p.theme.colors.background.tertiary};
  /* border: 1px solid ${(p) => p.theme.colors.background.tileDefault}; */
  border: ${(p) =>
    p.error ? `1px solid ${p.theme.colors.button.warning}` : "none"};
  padding: 16px;
  color: ${(p) => p.theme.colors.text.main};
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.r}px;
  font-weight: ${(p) => p.theme.text.weight.medium};
  outline: none;
  display: block;
  box-sizing: border-box;
  max-width: 500px;
  border-radius: 5px;
  width: 100%;

  ::placeholder {
    color: ${(p) => p.theme.colors.text.subtle};
  }
`;

const LoginWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  & > * + * {
    margin-top: 16px;
  }
`;

export default function LogIn() {
  // ui.start("authcontainer", {
  //   signInFlow: "popup",
  //   signInOptions: [auth.EmailAuthProvider.PROVIDER_ID],
  // });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submit = () => {
    signInWithEmailAndPassword(auth, email, password)
      // createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        console.log("SUCCESS!");
      })
      .catch((e) => console.log("ERROR", e));
  };

  return (
    <AppContainer nav={<LoginNav />} justifyContent="start" alignItems="center">
      <LoginWrapper>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <PrimaryButton onClick={submit}>Log In</PrimaryButton>
      </LoginWrapper>
    </AppContainer>
  );
}

function LoginNav() {
  return <NavTitle>Login</NavTitle>;
}
