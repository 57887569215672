import { UserPuzzle } from "../selectors/userPuzzleSelectors";

export default function moveToRackIndex(
  tileId: string,
  goalIndex: number,
  current: UserPuzzle
): UserPuzzle {
  const i = current.map[tileId].rackIndex;
  const currentIndex = i !== undefined ? i : Math.min();
  const currentlyBeyond = currentIndex > goalIndex;
  const index = goalIndex + (currentlyBeyond ? 0 : 1);
  const all = Object.keys(current.map).map((k) => ({
    id: k,
    state: current.map[k],
  }));

  const bumpFiltered = all.filter(
    (item) =>
      item.state.rackIndex !== undefined && item.state.rackIndex >= index
  );
  const bumpMapped = bumpFiltered.map((item) => ({
    [item.id]: { rackIndex: (item.state.rackIndex || 0) + 1 },
  }));
  const bumpMap = Object.assign({}, ...bumpMapped);
  return {
    ...current,
    map: {
      ...current.map,
      ...bumpMap,
      [tileId]: { rackIndex: index },
    },
  };
}
