import styled from "styled-components";
import { Header1 } from "../common/CommonElements";
import { PrimaryButton } from "../common/PrimaryButton";
import TextButton from "../common/TextButton";
import { buildBoard, buildTiles } from "../puzzle/selectors/puzzleSelectors";
import { DisplayedTile } from "../puzzle/types/displayedTile";
import ViewOnlyPuzzleBoard from "./ViewOnlyPuzzleBoard";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopSection = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.background.secondary};
  align-items: stretch;
`;

const BottomSection = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > * + * {
    margin-top: 20px;
  }
`;

const TopContent = styled.div`
  flex: 1;
  /* max-width: 340px; */
  display: flex;
  align-items: stretch;
  margin: 30px 0;
`;

const Header = styled(Header1)`
  margin-bottom: 20px;
  padding: 0 20px;
`;

const StartButton = styled(PrimaryButton)`
  padding: 16px 32px;
`;

export default function TutorialLanding({
  onStart,
  onClose,
}: {
  onClose: () => void;
  onStart: () => void;
}) {
  const board = buildBoard("XXXXX XXXXX XXXXX");
  const tiles = buildTiles("letsplay");
  const tilesByIndex: { [index: string]: DisplayedTile } = {
    "0,0": { state: "valid", tile: tiles[0] },
    "1,0": { state: "valid", tile: tiles[1] },
    "2,0": { state: "valid", tile: tiles[2] },
    "3,0": { state: "valid", tile: tiles[3] },
    "1,2": { state: "valid", tile: tiles[4] },
    "2,2": { state: "valid", tile: tiles[5] },
    "3,2": { state: "valid", tile: tiles[6] },
    "4,2": { state: "valid", tile: tiles[7] },
  };

  return (
    <Wrapper>
      <TopSection>
        <TopContent>
          <ViewOnlyPuzzleBoard
            uniqueId="TUTORIAL-LANDING"
            board={board}
            tilesByIndex={tilesByIndex}
          ></ViewOnlyPuzzleBoard>
        </TopContent>
      </TopSection>
      <BottomSection>
        <Header>
          How to play
          <br />
          Gridword!
        </Header>
        <StartButton onClick={() => onStart()}>Start</StartButton>
        <TextButton onClick={onClose}>Skip Tutorial</TextButton>
      </BottomSection>
    </Wrapper>
  );
}
