import React, { ReactNode, useState } from "react";
import TextAlert, { TextAlertButton } from "./TextAlert";

export function ConfirmAlert({
  children,
  buttonTitle,
  title,
  show,
  setShow,
  onConfirm,
}: {
  children: ReactNode;
  show: boolean;
  setShow: (show: boolean) => void;
  onConfirm: () => void;
  buttonTitle?: string;
  title?: string;
}) {
  const buttons: TextAlertButton[] = [
    { text: "Cancel" },
    {
      text: buttonTitle || "Confirm",
      type: "primary",
      action: onConfirm,
    },
  ];
  return (
    <TextAlert
      title={title}
      message={children}
      buttons={buttons}
      show={show}
      close={() => setShow(false)}
    ></TextAlert>
  );
}

export function useConfirmAlert(
  message: ReactNode,
  onConfirm: () => void,
  buttonTitle?: string,
  title?: string
): [ReactNode, (show: boolean) => void] {
  const [show, setShow] = useState(false);
  const alert = (
    <ConfirmAlert
      onConfirm={onConfirm}
      title={title}
      show={show}
      setShow={setShow}
      buttonTitle={buttonTitle}
    >
      {message}
    </ConfirmAlert>
  );
  return [alert, setShow];
}

export function NotificationAlert({
  title,
  children,
  show,
  setShow,
}: {
  title?: string;
  children: ReactNode;
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  //   const [show, setShow] = useRecoilState(tilesState);
  return (
    <TextAlert
      title={title}
      message={children}
      buttons={[{ text: "Ok" }]}
      show={show}
      close={() => setShow(false)}
    ></TextAlert>
  );
}

export function useNotificationAlert(
  message: string,
  title?: string
): [ReactNode, (show: boolean) => void] {
  const [show, setShow] = useState(false);
  const alert = (
    <NotificationAlert title={title} show={show} setShow={setShow}>
      {message}
    </NotificationAlert>
  );
  return [alert, setShow];
}
