import { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import placeTile from "../actions/placeTile";
import {
  nextboardIndex,
  selectedIndexAtom,
} from "../selectors/puzzleSelectionSelectors";
import { TileModel } from "../selectors/puzzleSelectors";
import { useUpdateUserPuzzle } from "../selectors/userPuzzleHistory";

export default function useTileTapped(): (tile: TileModel) => void {
  const updateUserPuzzle = useUpdateUserPuzzle();
  const [curLocation, setCurLocation] = useRecoilState(selectedIndexAtom);
  const nextLocation = useRecoilValue(nextboardIndex);

  return useCallback(
    (tile: TileModel) => {
      if (curLocation) {
        updateUserPuzzle((puzzle) => placeTile(tile.id, curLocation, puzzle));

        if (nextLocation) {
          setCurLocation(nextLocation);
        }
      }
    },
    [curLocation, nextLocation, setCurLocation, updateUserPuzzle]
  );
}
