import { useCallback } from "react";

export function useShareText(text: string, setCopied?: () => void): () => void {
  const handleCopyLink = useCallback((str: string) => {
    const textField = document.createElement("textarea");
    textField.innerText = str;
    document.body.appendChild(textField);
    if (window.navigator.platform === "iPhone") {
      textField.setSelectionRange(0, 99999);
    } else {
      textField.select();
    }
    document.execCommand("copy");
    textField.remove();
  }, []);
  return () => {
    if (navigator.share && window.location.protocol === "https:") {
      navigator.share({
        text,
      });
    } else {
      try {
        navigator.clipboard.writeText(text);
      } catch {
        handleCopyLink(text);
      }
      if (setCopied) setCopied();
    }
  };
}
