import React, { ReactNode } from "react";
import { FaRandom } from "react-icons/fa";
import styled, { useTheme } from "styled-components";
import { RegularText } from "../common/CommonElements";
import { PrimaryIconButton } from "../common/PrimaryButton";

const RandomSelectionContent = styled.div<{ height?: number }>`
  flex: 1;
  height: ${(p) => p.height}px;
  display: flex;
`;
const RandomSelectionDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  & > * + * {
    margin-top: 14px;
  }
`;
const RandomSelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px;
  & > * + * {
    margin-left: 20px;
  }
`;

export default function RandomSelection({
  children,
  title,
  onRandomize,
  height,
}: {
  children: ReactNode;
  title: string;
  onRandomize: () => void;
  height?: number;
}) {
  const theme = useTheme();
  return (
    <RandomSelectionWrapper>
      <RandomSelectionContent height={height}>
        {children}
      </RandomSelectionContent>
      <RandomSelectionDetail>
        <RegularText>{title}</RegularText>
        <PrimaryIconButton onClick={onRandomize} color={theme.colors.secondary}>
          <FaRandom></FaRandom>
        </PrimaryIconButton>
      </RandomSelectionDetail>
    </RandomSelectionWrapper>
  );
}
