import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import DimensionComponent, { ViewSize } from "../../common/DimensionComponent";
import { EmptyCell } from "../interactivePuzzle/InteractivePuzzleCell";
import { BoardModel } from "../selectors/puzzleSelectors";
import { PuzzleIndex } from "../selectors/userPuzzleSelectors";

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const GridDiv = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-direction: row; */
`;
const GridRowDiv = styled.div`
  display: flex;
  /* flex-direction: column; */
  flex-direction: row;
  flex: 1;
`;

function computeCellSize(
  viewSize: { width: number; height: number },
  rows: number,
  columns: number
) {
  const padding = 40;

  const horizontal = (viewSize.width || 100) - padding;
  const hSize = Math.floor(horizontal / columns);

  const vertical = (viewSize.height || 100) - padding;
  const vSize = Math.floor(vertical / rows);

  const maxTileSize = 50;

  return Math.min(maxTileSize, Math.min(vSize, hSize));
}

const GridContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export default function PuzzleBoard({
  puzzleKey,
  board,
  children,
}: {
  puzzleKey: string;
  board: BoardModel;
  children: (key: string, cellSize: number, index: PuzzleIndex) => ReactNode;
}) {
  const [viewSize, setViewSize] = useState<ViewSize>();

  const cellSize = computeCellSize(
    viewSize || { width: 100, height: 100 },
    board.length,
    board[0].length
  );

  return (
    <GridWrapper>
      <DimensionComponent buffer={350} onChange={setViewSize} />
      <GridContent>
        <GridDiv>
          {board.map((row, r) => {
            return (
              <GridRowDiv key={`GridRow ${puzzleKey} ${r}`}>
                {row.map((valid, c) => {
                  const cellKey = `GridCell ${puzzleKey} ${r} ${c}`;
                  if (!valid) {
                    return (
                      <EmptyCell key={cellKey} size={cellSize}></EmptyCell>
                    );
                  }
                  return children(cellKey, cellSize, {
                    x: c,
                    y: r,
                  });
                })}
              </GridRowDiv>
            );
          })}
        </GridDiv>
      </GridContent>
    </GridWrapper>
  );
}
