import { formatInTimeZone } from "date-fns-tz";
import React, { Fragment, ReactNode } from "react";
import { FaBars, FaTag } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  BackButton,
  useCanGoBack,
} from "../../global/hooks/useMaybeBackButton";
import { NavCentered } from "../common/AppContainer";
import { IconButton, Spacer, Subtitle, Title } from "../common/CommonElements";
import { keyToDate } from "../dailyQueue/selectors/dailyQueueSelectors";
import { puzzleAtom, PuzzleModel } from "../puzzle/selectors/puzzleSelectors";
import { PuzzleInfoButton } from "./PuzzleInfo";

const Buttons = styled.div`
  & > * + * {
    margin-left: 12px;
  }
`;

export default function PuzzleNavBar() {
  const navigate = useNavigate();
  const loc = useLocation();
  return (
    <Fragment>
      <Buttons>
        {loc.state !== "FROM-MAIN" ? <BackButton></BackButton> : undefined}
        <IconButton onClick={() => navigate("/")}>
          <FaBars />
        </IconButton>
      </Buttons>
      <NavCentered>
        <PuzzleName></PuzzleName>
      </NavCentered>
      <Spacer></Spacer>
      <PuzzleInfoButton />
    </Fragment>
  );
}

export function MenuOrBackButton() {
  const navigate = useNavigate();
  if (useCanGoBack()) {
    return <BackButton></BackButton>;
  }
  return (
    <IconButton onClick={() => navigate("/")}>
      <FaBars />
    </IconButton>
  );
}

const PuzzleNameWrapper = styled.div`
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-items: center;
`;

export function PuzzleName() {
  const puzzle = useRecoilValue(puzzleAtom);
  return (
    <PuzzleNameWrapper>
      <Subtitle>{subtitleForPuzzle(puzzle)}</Subtitle>
      {titleForPuzzle(puzzle)}
    </PuzzleNameWrapper>
  );
}

function subtitleForPuzzle(puzzle: PuzzleModel): ReactNode {
  switch (puzzle.source.key) {
    case "daily":
      return `Daily Puzzle #${puzzle.source.index}`;
    case "tagged":
      return `Tagged Puzzle`;
    case "none":
      return `-`;
  }
}

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * + * {
    margin-left: 10px;
  }
`;

const TagIcon = styled(FaTag)`
  font-size: ${(p) => p.theme.text.size.m}px;
  color: ${(p) => p.theme.colors.text.subtle};
`;

function PuzzleTag({ tag }: { tag: string }) {
  return (
    <TagWrapper>
      <TagIcon />
      <Title>{tag}</Title>
    </TagWrapper>
  );
}

function titleForPuzzle(puzzle: PuzzleModel): ReactNode {
  switch (puzzle.source.key) {
    case "daily":
      const date = keyToDate(puzzle.source.day);
      return <Title>{formatInTimeZone(date, "GMT", "M/d/yy")}</Title>;
    case "tagged":
      return <PuzzleTag tag={puzzle.source.tag} />;
    case "none":
      return <Title>-</Title>;
  }
}
