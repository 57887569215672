import styled, { useTheme } from "styled-components";
import { colorForState } from "../../puzzle/board/CharacterTile";
import ScoreSummary from "../components/ScoreSummary";
import ExampleGrid from "./ExampleGrid";
import { TipCell, TipContent, TipHeader, TipRow } from "./TipComponents";

const Tip2ScoreWrapper = styled.div`
  margin-top: 20px;
`;

export default function Tip2() {
  const board = "XXX XXX XXX";
  const theme = useTheme();
  return (
    <div>
      <TipHeader>Reuse tiles for more points</TipHeader>
      <TipContent>
        <TipRow>
          <TipCell>
            <ExampleGrid
              boardStr={board}
              state="ready"
              tilesStr="mad"
              tileIndices={["1,0", "1,1", "1,2"]}
            />
            <Tip2ScoreWrapper>
              <ScoreSummary color={colorForState("ready", theme)}>
                {"6"}
              </ScoreSummary>
            </Tip2ScoreWrapper>
          </TipCell>
          <TipCell>
            <ExampleGrid
              boardStr={board}
              state="complete"
              tilesStr="mad"
              tileIndices={["1,0", "1,1", "2,1"]}
            />
            <Tip2ScoreWrapper>
              <ScoreSummary color={colorForState("complete", theme)}>
                {"7"}
              </ScoreSummary>
            </Tip2ScoreWrapper>
          </TipCell>
        </TipRow>
      </TipContent>
    </div>
  );
}
