import {
  GridCellContentWrapper,
  PuzzleCellWrapper,
} from "../interactivePuzzle/InteractivePuzzleCell";
import { BoardModel } from "../selectors/puzzleSelectors";
import PuzzleBoard from "./PuzzleBoard";

export default function EmptyBoard({
  id,
  board,
}: {
  board: BoardModel;
  id: string;
}) {
  return (
    <PuzzleBoard board={board} puzzleKey={id}>
      {(key, size, index) => {
        return (
          <PuzzleCellWrapper key={key} size={size}>
            <GridCellContentWrapper
              highlight={false}
              size={size}
            ></GridCellContentWrapper>
          </PuzzleCellWrapper>
        );
      }}
    </PuzzleBoard>
  );
}
