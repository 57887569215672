import { Fragment, useState } from "react";
// import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import CharacterTile from "../board/CharacterTile";
import { SelectionDirection } from "../selectors/puzzleSelectionSelectors";
import { BoardModel } from "../selectors/puzzleSelectors";
import { PuzzleIndex } from "../selectors/userPuzzleSelectors";
import { DisplayedTile } from "../types/displayedTile";
import PuzzleCellSelection from "./PuzzleCellSelection";
import TileDraggable from "./TileDraggable";
import TileDroppable from "./TileDroppable";

export const PuzzleCellWrapper = styled.div<{ size: number }>`
  padding: 1px;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  position: relative;
`;

export const GridCellContentWrapper = styled.div<{
  highlight: boolean;
  size: number;
  // selected: boolean;
}>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  position: relative;
  border-radius: 3px;
  background-color: ${(p) =>
    p.highlight
      ? p.theme.colors.background.tileHighlighted
      : p.theme.colors.background.tileDefault};
`;

export type PuzzleCellContent = DisplayedTile | "empty";

export interface PuzzleCellModel {
  index: PuzzleIndex;
  content: PuzzleCellContent;
  selection: SelectionDirection | undefined;
}

interface Action {
  key: string;
}

interface TileDroppedAction extends Action {
  key: "TileDropped";
  tile: DisplayedTile;
  index: PuzzleIndex;
}

interface CellTappedAction extends Action {
  key: "CellTapped";
  cell: PuzzleCellModel;
}

export type PuzzleCellAction = TileDroppedAction | CellTappedAction;

export function EmptyCell({ size }: { size: number }) {
  return <PuzzleCellWrapper size={size} />;
}

export default function InteractivePuzzleCell({
  board,
  cellModel,
  size,
  onAction,
}: {
  board: BoardModel;
  cellModel: PuzzleCellModel;
  size: number;
  onAction: (action: PuzzleCellAction) => void;
}) {
  const [clickStart, setClickStart] = useState<Date | undefined>();

  return (
    <PuzzleCellWrapper size={size}>
      <TileDroppable
        onDrop={(tile) =>
          onAction({ key: "TileDropped", tile, index: cellModel.index })
        }
      >
        {(dropping) => (
          <Fragment>
            <GridCellContentWrapper
              size={size}
              highlight={dropping}
              onMouseDown={() => {
                setClickStart(new Date());
              }}
              onMouseLeave={() => setClickStart(undefined)}
              onMouseUp={() => {
                if (
                  // Don't fire tap event if drag and place
                  // tile back on same cell
                  cellModel.content === "empty" ||
                  cellModel.selection === undefined ||
                  (clickStart &&
                    new Date().getTime() - clickStart.getTime() < 300)
                ) {
                  onAction({ key: "CellTapped", cell: cellModel });
                }
                setClickStart(undefined);
              }}
            >
              {cellModel.content !== "empty" ? (
                <DraggablePuzzleTile
                  tile={cellModel.content}
                  size={size}
                  dropping={dropping}
                ></DraggablePuzzleTile>
              ) : undefined}
            </GridCellContentWrapper>
            {cellModel.selection !== undefined ? (
              <PuzzleCellSelection
                direction={cellModel.selection}
                size={size}
                cellModel={cellModel}
                board={board}
              ></PuzzleCellSelection>
            ) : undefined}
          </Fragment>
        )}
      </TileDroppable>
    </PuzzleCellWrapper>
  );
}

function DraggablePuzzleTile({
  tile,
  size,
  dropping,
}: {
  dropping: boolean;
  size: number;
  tile: DisplayedTile;
}) {
  return (
    <TileDraggable tile={tile}>
      {(dragging) => (
        <CharacterTile
          size={size}
          semiOpaque={dragging || dropping}
          tile={tile}
        ></CharacterTile>
      )}
    </TileDraggable>
  );
}
