import { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import moveToEndOfRack from "../actions/moveToEndOfRack";
import {
  previousboardIndex,
  selectedIndexAtom,
  selectedLocationTileSelector,
} from "../selectors/puzzleSelectionSelectors";
import { boardState } from "../selectors/puzzleStateSelectors";
import { useUpdateUserPuzzle } from "../selectors/userPuzzleHistory";
import { indexToKey } from "../selectors/userPuzzleSelectors";

export default function useBackspace(): () => void {
  const updateUserPuzzle = useUpdateUserPuzzle();
  const prev = useRecoilValue(previousboardIndex);
  const [, setSelectedLocation] = useRecoilState(selectedIndexAtom);
  const currentTile = useRecoilValue(selectedLocationTileSelector);
  const boardTiles = useRecoilValue(boardState);
  return useCallback(() => {
    if (currentTile) {
      updateUserPuzzle((puzzle) => moveToEndOfRack(currentTile.id, puzzle));
    } else if (prev !== undefined) {
      setSelectedLocation(prev);

      const prevTile = boardTiles[indexToKey(prev)];
      if (prevTile && prevTile !== "empty") {
        updateUserPuzzle((puzzle) => moveToEndOfRack(prevTile.id, puzzle));
      }
    }
  }, [boardTiles, currentTile, prev, setSelectedLocation, updateUserPuzzle]);
}
