import React, { Fragment, useEffect } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import styled from "styled-components";
import { RegularText } from "../common/CommonElements";
import { NoScroll } from "../common/GlobalStyles";
import submit from "../puzzle/actions/submit";
import CompletedPuzzle from "../puzzle/board/CompletedPuzzle";
import PuzzleDnd from "../puzzle/game/PuzzleDnd";
import Results from "../puzzle/game/Results";
import TileRack from "../puzzle/game/tileRack/TileRack";
import InteractivePuzzle from "../puzzle/interactivePuzzle/InteractivePuzzle";
import {
  selectedDirectionAtom,
  selectedIndexAtom,
} from "../puzzle/selectors/puzzleSelectionSelectors";
import {
  buildBoard,
  buildTiles,
  puzzleAtom,
  PuzzleModel,
} from "../puzzle/selectors/puzzleSelectors";
import {
  tileRack,
  tilesByIndex,
} from "../puzzle/selectors/puzzleStateSelectors";
import {
  allWordsValid,
  puzzleValid,
} from "../puzzle/selectors/puzzleValidateSelectors";
import {
  defaultUserPuzzle,
  keyForPuzzleSource,
  userPuzzleForKey,
  userPuzzleSelector,
} from "../puzzle/selectors/userPuzzleSelectors";
import PuzzleStateWrapper from "../puzzle/stateWrapper/PuzzleStateWrapper";
import TutorialButton from "./components/TutorialButton";
import TutorialFooter from "./components/TutorialFooter";
import TutorialHeader from "./components/TutorialHeader";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopSection = styled.div`
  flex: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.background.secondary};
  align-items: stretch;
`;

const BottomSection = styled.div`
  flex: 5;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;

  & > * + * {
    margin-top: 20px;
  }
`;

const TopContent = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  margin: 0 0 30px;
  flex-direction: column;
`;

const TileRackContainer = styled.div`
  height: 70px;
`;

export default function TutorialInteractive(props: {
  onClose: () => void;
  onNext: () => void;
  onBack: () => void;
}) {
  const puzzle: PuzzleModel = {
    board: buildBoard("XXX XXX XXX"),
    tiles: buildTiles("tago"),
    source: { key: "none", id: "Tutorial" },
  };

  return (
    <Wrapper>
      <PuzzleDnd>
        <PuzzleStateWrapper puzzle={puzzle} fallback={<Placeholder />}>
          <NoScroll />
          <TutorialInteractiveContent {...props} />
        </PuzzleStateWrapper>
      </PuzzleDnd>
    </Wrapper>
  );
}

const Flex1 = styled.div`
  flex: 1;
`;

function Placeholder() {
  return (
    <Fragment>
      <TopSection>
        <TopContent>
          <TutorialHeader onClose={() => {}}>How to Play</TutorialHeader>
          <Flex1 />
        </TopContent>
      </TopSection>
      <BottomSection>
        <Flex1 />
        <TutorialFooter onBack={() => {}}>
          <TutorialButton disabled={true} onClick={() => {}}>
            Submit
          </TutorialButton>
        </TutorialFooter>
      </BottomSection>
    </Fragment>
  );
}

const InstructionText = styled(RegularText)`
  /* color: ${(p) => p.theme.colors.primary}; */
  text-align: center;
  padding: 0 30px;
`;

const BottomContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > * {
    margin-top: 30px;
  }
`;

function TutorialInteractiveContent({
  onClose,
  onNext,
  onBack,
}: {
  onClose: () => void;
  onNext: () => void;
  onBack: () => void;
}) {
  const puzzle = useRecoilValue(puzzleAtom);
  const userPuzzle = useRecoilValue(userPuzzleSelector);
  const resetSelection = useResetRecoilState(selectedDirectionAtom);
  const resetIndex = useResetRecoilState(selectedIndexAtom);
  const puzzleKey = keyForPuzzleSource(puzzle.source);
  const [, setUserPuzzle] = useRecoilState(userPuzzleForKey(puzzleKey));

  useEffect(() => {
    setUserPuzzle(defaultUserPuzzle(puzzle));
    resetSelection();
    resetIndex();
  }, [puzzle, resetIndex, resetSelection, setUserPuzzle]);

  return (
    <Fragment>
      <TopSection>
        <TopContent>
          <TutorialHeader onClose={onClose}>How to Play</TutorialHeader>
          {userPuzzle.submitted ? <CompletedPuzzle /> : <InteractivePuzzle />}
          <TileRackContainer>
            <TileRack containerWidth={1000} centerTiles={true} />
          </TileRackContainer>
        </TopContent>
      </TopSection>
      <BottomSection>
        {userPuzzle.submitted ? (
          <SubmittedBottomSection onBack={onBack} onNext={onNext} />
        ) : (
          <UnsubmittedBottomSection onBack={onBack} />
        )}
      </BottomSection>
    </Fragment>
  );
}

function SubmittedBottomSection({
  onBack,
  onNext,
}: {
  onBack: () => void;
  onNext: () => void;
}) {
  return (
    <Fragment>
      <BottomContent>
        <Results disableShare={true} />
        <InstructionText>
          Make sure to share and compare scores with friends!
        </InstructionText>
      </BottomContent>
      <TutorialFooter onBack={onBack}>
        <TutorialButton onClick={onNext}>Next</TutorialButton>
      </TutorialFooter>
    </Fragment>
  );
}

function UnsubmittedBottomSection({ onBack }: { onBack: () => void }) {
  const instruction = useInstructionText();
  const valid = useRecoilValue(puzzleValid);
  const rack = useRecoilValue(tileRack);
  const puzzle = useRecoilValue(puzzleAtom);
  const puzzleKey = keyForPuzzleSource(puzzle.source);
  const userPuzzle = useRecoilValue(userPuzzleSelector);
  const [, setUserPuzzle] = useRecoilState(userPuzzleForKey(puzzleKey));
  return (
    <Fragment>
      <BottomContent>
        <InstructionText>{instruction}</InstructionText>
      </BottomContent>
      <TutorialFooter onBack={onBack}>
        <TutorialButton
          disabled={rack.length > 0 || !valid}
          onClick={() => setUserPuzzle((current) => submit(userPuzzle))}
        >
          Next
        </TutorialButton>
      </TutorialFooter>
    </Fragment>
  );
}

function useInstructionText() {
  const placedTileCount = Object.keys(useRecoilValue(tilesByIndex)).length;
  const rackCount = useRecoilValue(tileRack).length;
  const validWords = useRecoilValue(allWordsValid);
  const valid = useRecoilValue(puzzleValid);

  if (placedTileCount === 0)
    return "Tap or drag tiles to place anywhere on board";
  if (placedTileCount === 1 || !validWords)
    return "Place more tiles or rearrange to spell words";
  if (rackCount !== 0) {
    return "Valid words turn blue. Try to use all your tiles";
  }
  if (validWords && !valid) {
    return "Words must form a single group";
  }

  return "When you're ready, tap next to see your final score";
}
