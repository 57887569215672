import { TileStateMap, UserPuzzle } from "../selectors/userPuzzleSelectors";

export default function rackAllTiles(current: UserPuzzle): UserPuzzle {
  let index =
    Math.max(
      ...Object.values(current.map).flatMap((v) => {
        if (v.rackIndex) return [v.rackIndex];
        return [];
      })
    ) + 1;
  var changes: TileStateMap = {};
  Object.keys(current.map).forEach((key) => {
    const item = current.map[key];
    if (item.boardIndex) {
      changes[key] = { rackIndex: index++ };
    }
  });
  return {
    ...current,
    map: {
      ...current.map,
      ...changes,
    },
  };
}
