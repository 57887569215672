import { useCallback, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { darkModeSelector } from "../selectors/DarkModeAtom";

interface DarkModeChange {
  key: "darkMode";
  enabled: boolean;
}

type ReactNativeMessage = DarkModeChange;

function encodeMessage(message: ReactNativeMessage) {
  switch (message.key) {
    case "darkMode":
      return `darkMode:${message.enabled}`;
  }
}

export function useReactNativeSendMessage() {
  return (message: ReactNativeMessage) => {
    const post = (window as any).ReactNativeWebView.postMessage;
    if (post) {
      post(encodeMessage(message), "*");
    }
  };
}

export function useReactNativeListenForMessages() {
  const darkModeEnabled = useRecoilValue(darkModeSelector);
  const sendMessage = useReactNativeSendMessage();

  const messageHandler = useCallback(
    (event: any) => {
      const data = event.data;
      if (data === "sendDarkMode") {
        sendMessage({ key: "darkMode", enabled: darkModeEnabled });
      }
    },
    [darkModeEnabled, sendMessage]
  );

  window.addEventListener("message", messageHandler);

  useEffect(() => {
    return () => window.removeEventListener("message", messageHandler);
  }, [messageHandler]);
}
