import { atomFamily, DefaultValue, selector } from "recoil";
import { puzzleAtom, PuzzleModel, PuzzleSource } from "./puzzleSelectors";

export interface TileState {
  boardIndex?: PuzzleIndex;
  rackIndex?: number;
}

export interface PuzzleIndex {
  x: number;
  y: number;
}

export function keyToIndex(key: string): PuzzleIndex {
  const parts = key.split(",");
  const x = parseInt(parts[0]) || 0;
  const y = parseInt(parts[1]) || 0;
  return { x, y };
}

export function indexToKey(index: PuzzleIndex) {
  return `${index.x},${index.y}`;
}

export type TileStateMap = { [tileId: string]: TileState };
export interface UserPuzzle {
  map: TileStateMap;
  submitted: boolean;
}

function defaultTileStates(puzzle: PuzzleModel): TileStateMap {
  const states: { [id: string]: TileState }[] = puzzle.tiles.map((m, i) => ({
    [m.id]: { rackIndex: i },
  }));
  return Object.assign({}, ...states);
}

const USER_PUZZLE_KEY = "UserPuzzle";
function userPuzzleLocation(key: string) {
  return `${USER_PUZZLE_KEY}-${key}`;
}

export function defaultUserPuzzle(puzzle: PuzzleModel): UserPuzzle {
  return { map: defaultTileStates(puzzle), submitted: false };
}

// export const forceUpdateUserPuzzle = atom<string>({
//   key: "forceUpdateUserPuzzle",
//   default: "-",
// });

export function keyForPuzzleSource(source: PuzzleSource) {
  switch (source.key) {
    case "daily":
      return source.day;
    case "tagged":
      return `TAG:${source.tag}`;
    case "none":
      return source.id;
  }
}

export const puzzleKey = selector({
  key: "puzzleKey",
  get: ({ get }) => {
    const puzzle = get(puzzleAtom);
    return keyForPuzzleSource(puzzle.source);
  },
});

export const userPuzzleForKey = atomFamily<UserPuzzle | undefined, string>({
  key: "userPuzzleForStorageKey",
  default: (key: string) => {
    const location = userPuzzleLocation(key);
    const value = JSON.parse(
      localStorage.getItem(location) || "{}"
    ) as UserPuzzle;
    if (!value.map) {
      return undefined;
    } else {
      var newMap: TileStateMap = {};
      Object.keys(value.map).forEach((key) => {
        var item = value.map[key];
        const boardLocation = (item as any).boardLocation;
        if (boardLocation) {
          item.boardIndex = boardLocation;
        }
        newMap[key] = item;
      });
      return {
        ...value,
        map: newMap,
      };
    }
  },
  effects: (key) => [
    ({ onSet }) => {
      onSet(async (newValue) => {
        if (newValue instanceof DefaultValue) {
          console.log("wht to do?");
          return;
        }
        // const puzzle = await getPromise(puzzleAtom);
        // const key = keyForPuzzleSource(puzzle.source);
        const location = userPuzzleLocation(key);
        localStorage.setItem(location, JSON.stringify(newValue));
      });
    },
  ],
});

export const userPuzzleSelector = selector<UserPuzzle>({
  key: "userPuzzle",
  get: ({ get }) => {
    // get(forceUpdateUserPuzzle);
    const puzzle = get(puzzleAtom);
    const key = keyForPuzzleSource(puzzle.source);
    const userPuzzle = get(userPuzzleForKey(key));
    if (userPuzzle) return userPuzzle;
    return defaultUserPuzzle(puzzle);
  },
});

// const UserPuzzleStateKeys = {
//   userId: "userId",
//   dayKey: "dayKey",
// };

// export const userPuzzleStateConverter = {
//   toFirestore: (data: UserPuzzleState) => {
//     // const tileState = JSON.stringify(data.tileState);
//     return {
//       tileState: data.tileState,
//       userId: data.userId,
//       dayKey: data.dayKey,
//       submitted: data.submitted || false,
//     };
//   },
//   fromFirestore: (snap: QueryDocumentSnapshot): UserPuzzleState => {
//     const data = snap.data();
//     // const tileState = JSON.parse(data.tileState);
//     return {
//       id: snap.id,
//       tileState: data.tileState,
//       userId: data.userId,
//       dayKey: data.dayKey,
//       submitted: data.submitted || false,
//     };
//   },
// };

// export async function getUserPuzzle(
//   userId: string,
//   dayKey: string
// ): Promise<UserPuzzleState | undefined> {
//   const ref = collection(db, Collections.userPuzzleStates).withConverter(
//     userPuzzleStateConverter
//   );

//   const q = query(
//     ref,
//     where(UserPuzzleStateKeys.dayKey, "==", dayKey),
//     where(UserPuzzleStateKeys.userId, "==", userId)
//   );

//   try {
//     const snap = await getDocs(q);
//     if (snap.empty) {
//       return undefined;
//     }
//     return snap.docs[0].data();
//   } catch {
//     return undefined;
//   }
// }

// const existingOrDefaultUserPuzzleState = selector<TilesState>({
//   key: "existingOrDefaultUserPuzzleState",
//   get: async ({ get }) => {
//     // const userId = get(userIdSelector);
//     const dayKey = get(currentDayKey);
//     const existing = await getUserPuzzle(userId, dayKey);
//     if (existing)
//       return {
//         map: existing.tileState,
//         id: existing.id,
//         submitted: existing.submitted,
//       };
//     return {
//       map: get(defaultTileStates),
//       id: undefined,
//       submitted: false,
//     };
//   },
// });

// export const tilesState = atom<TilesState>({
//   key: "tileStates",
//   default: existingOrDefaultUserPuzzleState,
//   effects: [
//     ({ getPromise, onSet, trigger, setSelf }) => {
//       var cancel = false;

//       onSet(async (newState, oldState) => {
//         const dayId = getPromise(currentDay);
//         localStorage.setItem(`DAYPUZZLE-${dayId}`, JSON.stringify(newState));
//         // if (newState.id) {
//         //   const ref = doc(
//         //     db,
//         //     Collections.userPuzzleStates,
//         //     newState.id
//         //   ).withConverter(userPuzzleStateConverter);

//         //   await updateDoc(ref, {
//         //     tileState: newState.map,
//         //     submitted: newState.submitted,
//         //   });
//         // } else {
//         //   const date = await getPromise(currentDayKey);
//         //   const userId = await getPromise(userIdSelector);
//         //   if (cancel) return;
//         //   const ref = collection(
//         //     db,
//         //     Collections.userPuzzleStates
//         //   ).withConverter(userPuzzleStateConverter);

//         //   const output = await addDoc(ref, {
//         //     userId: userId,
//         //     dayKey: date,
//         //     tileState: newState.map,
//         //     id: "",
//         //     submitted: false,
//         //   });
//         //   setSelf({ id: output.id, map: newState.map, submitted: false });
//         // }
//       });

//       return () => {
//         cancel = true;
//       };
//     },
//   ],
// });
