import React from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import makeArray from "../../../../global/utils/makeArray";
import { tileRack } from "../../selectors/puzzleStateSelectors";
import { DisplayedTile } from "../../types/displayedTile";
import DroppableRackCell from "./DroppableRackCell";

const TileRackWrapper = styled.div<{ height: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${(p) => p.height}px;
`;

const TileRackContent = styled.div<{ centerTiles?: boolean }>`
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(p) => (p.centerTiles ? "center" : "start")};
`;

interface RackDimensions {
  rows: number;
  columns: number;
  tileSize: number;
  requiredHeight: number;
  cellCount: number;
}

function tileSizeForWidth(width: number): number {
  const target = Math.floor(width / 9.7);
  return Math.min(Math.max(target, 38), 46);
}

function rackDimensions(
  containerWidth: number,
  tilesCount: number
): RackDimensions {
  const tileRackPadding = 16;
  const minTileRackCountAcross = 7;
  const maxTileWidth = tileSizeForWidth(containerWidth);
  const availableWidth = containerWidth - 2 * tileRackPadding;
  const tileSize = Math.min(
    Math.floor(availableWidth / minTileRackCountAcross),
    maxTileWidth
  );
  let columns = Math.floor(availableWidth / tileSize);
  const rows =
    Math.floor(tilesCount / columns) + (tilesCount % columns === 0 ? 0 : 1);
  if (rows === 1) columns = tilesCount;

  const requiredHeight = tileSize * rows + 2 * tileRackPadding;

  // console.log("W", tileRackTileWidth, tileRackColumns);
  // console.log("H", tileRackTilesHeight + 2 * tileRackPadding, tileRackRowCount);
  return {
    rows,
    columns,
    tileSize,
    requiredHeight,
    cellCount: rows * columns,
  };
}

export default function TileRack({
  containerWidth,
  centerTiles,
}: {
  centerTiles?: boolean;
  containerWidth: number;
}) {
  const tiles: DisplayedTile[] = useRecoilValue(tileRack).map((t) => ({
    tile: t,
    state: "default",
  }));

  const dimensions = rackDimensions(containerWidth, tiles.length);

  return (
    <TileRackWrapper height={dimensions.requiredHeight}>
      <TileRackContent centerTiles={centerTiles}>
        {makeArray(dimensions.cellCount).map((i) => {
          const tile = tiles[i];
          return (
            <DroppableRackCell
              size={dimensions.tileSize}
              key={tile ? `tile${tile.tile.id}` : `rackSlotOfIndex${i}`}
              tile={tile}
            ></DroppableRackCell>
          );
        })}
      </TileRackContent>
    </TileRackWrapper>
  );
}
