import React, { ReactNode } from "react";
import ReactModal from "react-modal";
import { useTheme } from "styled-components";

export default function GridwordModal({
  show,
  children,
}: {
  show: boolean;
  children: ReactNode;
}) {
  const theme = useTheme();
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={show}
      style={{
        overlay: {
          background: theme.colors.background.modalOverlay,
          zIndex: 200,
        },
        content: {
          background: theme.colors.background.main,
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          maxWidth: 280,
          border: "none",
          borderRadius: 8,
          zIndex: 201,
        },
      }}
    >
      {children}
    </ReactModal>
  );
}
