import React from "react";
import styled from "styled-components";

const ToggleWrapper = styled.div<{ enabled: boolean }>`
  background-color: ${(p) =>
    !p.enabled
      ? p.theme.colors.background.tileDefault
      : p.theme.colors.primary};
  border-radius: 50px;
  padding: 3px;
  width: 54px;
  height: 28px;
  display: flex;
  border: none;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
`;

const ToggleContent = styled.div<{ enabled: boolean }>`
  flex: 1;
  display: flex;
  justify-content: ${(p) => (p.enabled ? "end" : "start")};
`;

const ToggleSwitch = styled.div`
  background-color: ${(p) => p.theme.colors.background.main};
  width: 60%;
  height: 100%;
  border-radius: 50px;
`;

export default function Toggle({
  on,
  setOn,
}: {
  on: boolean;
  setOn: (on: boolean) => void;
}) {
  return (
    <ToggleWrapper enabled={on} onClick={() => setOn(!on)}>
      <ToggleContent enabled={on}>
        <ToggleSwitch></ToggleSwitch>
      </ToggleContent>
    </ToggleWrapper>
  );
}
