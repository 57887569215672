import styled from "styled-components";
import { BodyFullHeight, ThemedBackground } from "./GlobalStyles";

const LoadingWrapper = styled.div<{ fullHeight?: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(p) => p.theme.text.family.secondary};
  font-size: ${(p) => p.theme.text.size.h1}px;
  color: ${(p) => p.theme.colors.text.main};
  background: ${(p) => p.theme.colors.background.board};
  /* overflow: hidden; */
  min-height: ${(p) => (p.fullHeight ? "100vh" : "")};
  min-height: ${(p) => (p.fullHeight ? "-webkit-fill-available" : "")};
  display: flex;
`;

export default function Loading({ fullHeight }: { fullHeight?: boolean }) {
  return (
    <LoadingWrapper fullHeight={fullHeight}>
      <ThemedBackground />
      <BodyFullHeight />
      &nbsp;&nbsp;Loading...
    </LoadingWrapper>
  );
}
