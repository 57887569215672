import React from "react";
import styled from "styled-components";
import CharacterTile from "../puzzle/board/CharacterTile";
import { TileModel } from "../puzzle/selectors/puzzleSelectors";

const TilesWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const TilesContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  & > * {
    margin: 2px;
  }
`;

export default function TilesList({ tiles }: { tiles: TileModel[] }) {
  return (
    <TilesWrapper>
      <TilesContent>
        {tiles.map((t) => (
          <CharacterTile
            size={28}
            key={t.id}
            tile={{ state: "default", tile: t }}
          />
        ))}
      </TilesContent>
    </TilesWrapper>
  );
}
