import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import NotFound from "../notFound/NotFound";
import {
  puzzleForDay,
  puzzleForTag,
} from "../puzzle/selectors/puzzleSelectors";
import PuzzlePage from "./PuzzlePage";

export default function PuzzleForId() {
  const params = useParams();
  const puzzleId = params.puzzleId as string;

  return (
    <ErrorBoundary fallback={<NotFound />}>
      <PuzzleForIdContent puzzleId={puzzleId} />
    </ErrorBoundary>
  );
}

function PuzzleForIdContent({ puzzleId }: { puzzleId: string }) {
  const comps = puzzleId.split("-");

  if (comps.length === 3) {
    return <PuzzleForDay dayKey={puzzleId} />;
  } else if (comps.length === 2) {
    return <PuzzleForTag tag={puzzleId} />;
  }
  throw new Error("Invalid puzzleId");
}

export function PuzzleForTag({ tag }: { tag: string }) {
  const puzzle = useRecoilValue(puzzleForTag(tag));
  return <PuzzlePage puzzle={puzzle}></PuzzlePage>;
}

export function PuzzleForDay({ dayKey }: { dayKey: string }) {
  const puzzle = useRecoilValue(puzzleForDay(dayKey));
  return <PuzzlePage puzzle={puzzle}></PuzzlePage>;
}
