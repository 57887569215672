import { useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  darkModeAtom,
  systemDarkMode,
} from "../../global/selectors/DarkModeAtom";

export default function useAutoDarkMode() {
  const [current, setDarkMode] = useRecoilState(darkModeAtom);
  useEffect(() => {
    if (current === undefined) {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", (e) => setDarkMode(systemDarkMode()));
    }
  }, [current, setDarkMode]);
}
