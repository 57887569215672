import { FaTimes } from "react-icons/fa";
import styled from "styled-components";

export const IconButton = styled.button<{ size?: number; colorize?: boolean }>`
  color: ${(p) =>
    p.disabled
      ? p.theme.colors.button.disabled
      : p.colorize
      ? p.theme.colors.secondary
      : p.theme.colors.text.main};
  font-size: ${(p) => p.size || p.theme.text.size.l}px;
  background: none;
  border: none;
  font-weight: 700;
  stroke-width: 2;
  flex: none;
  padding: 0;
  /* margin: 0; */

  &:active {
    opacity: 0.5;
  }

  @media (hover: hover) {
    &:hover {
      opacity: ${(p) => (p.disabled ? "" : "0.8")};
      cursor: ${(p) => (p.disabled ? "" : "pointer")};
    }
  }
`;

export function CloseButton({ onClick }: { onClick: () => void }) {
  return (
    <CloseButtonWrapper onClick={onClick}>
      <FaTimes />
    </CloseButtonWrapper>
  );
}

export const CloseButtonWrapper = styled(IconButton)`
  position: absolute;
  top: 0px;
  right: 0px;
  flex: 1;
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const Title = styled.div`
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.m}px;
  color: ${(p) => p.theme.colors.text.main};
  font-weight: 800;
`;

export const Subtitle = styled.div`
  color: ${(p) => p.theme.colors.text.subtle};
  font-size: ${(p) => p.theme.text.size.s}px;
  font-family: ${(p) => p.theme.text.family.primary};
  font-weight: ${(p) => p.theme.text.weight.bold};
  text-transform: uppercase;
`;

export const RegularText = styled.div`
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.m}px;
  color: ${(p) => p.theme.colors.text.main};
  font-weight: 600;
`;

export const RegularTextError = styled(RegularText)`
  color: ${(p) => p.theme.colors.button.warning};
  width: 100%;
  text-align: center;
`;

export const SmallText = styled.div`
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.s}px;
  color: ${(p) => p.theme.colors.text.main};
  font-weight: 700;
`;

export const Header1 = styled.div`
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.l}px;
  color: ${(p) => p.theme.colors.text.main};
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
`;

export const Header2 = styled.div`
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.l}px;
  color: ${(p) => p.theme.colors.text.main};
  font-weight: 600;
  text-align: center;
`;
