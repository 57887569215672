import styled from "styled-components";

const TextButton = styled.button<{ disabled?: boolean }>`
  background: none;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  color: ${(p) =>
    p.disabled ? p.theme.colors.text.subtle : p.theme.colors.text.main};
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.m}px;
  font-weight: 600;

  &:active {
    opacity: 0.8;
  }

  @media (hover: hover) {
    &:hover {
      opacity: ${(p) => (p.disabled ? 1 : 0.8)};
      cursor: pointer;
    }
  }
`;

export default TextButton;
