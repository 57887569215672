import { addDays, differenceInDays, format } from "date-fns";
import { Fragment } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import makeArray from "../../global/utils/makeArray";
import AppContainer, { NavTitle } from "../common/AppContainer";
import { RegularText, Spacer } from "../common/CommonElements";
import {
  dateToKey,
  keyToDate,
  keyToLocalDate,
  localDateToKey,
} from "../dailyQueue/selectors/dailyQueueSelectors";
import { userPuzzleForKey } from "../puzzle/selectors/userPuzzleSelectors";
import { MenuOrBackButton } from "../puzzlePage/PuzzleNavBar";

function ArchiveNav() {
  return (
    <Fragment>
      <MenuOrBackButton />
      <NavTitle>Daily Archive</NavTitle>
      <Spacer></Spacer>
    </Fragment>
  );
}

const ArchiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  & > * {
    box-sizing: border-box;
    width: 100%;
    max-width: 380px;
  }

  & > * + * {
    margin-top: 16px;
  }
`;

export default function DailyArchive() {
  const firstDay = "2022-04-22";
  const firstDayDate = keyToDate(firstDay);

  const yesterdayLocalDate = addDays(new Date(), -1);
  const yesterdayKey = localDateToKey(yesterdayLocalDate);
  const yesterdayDate = keyToDate(yesterdayKey);

  const diff = differenceInDays(yesterdayDate, firstDayDate);
  const dayTags = makeArray(diff).map((i) => {
    const date = addDays(yesterdayDate, -i);
    return dateToKey(date);
  });

  return (
    <AppContainer nav={<ArchiveNav />}>
      <ArchiveWrapper>
        {dayTags.map((tag) => (
          <DayRow tag={tag} key={tag} />
        ))}
      </ArchiveWrapper>
    </AppContainer>
  );
}

const DayRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  padding: 20px;
  flex: 1;
  background: ${(p) => p.theme.colors.background.main};
  border-radius: 5px;

  & > * + * {
    margin-left: 10px;
  }

  &:active {
    opacity: 0.8;
  }
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

const ArchiveStatus = styled(RegularText)<{
  status: "started" | "completed";
}>`
  color: ${(p) =>
    p.status === "completed"
      ? p.theme.colors.primary
      : p.theme.colors.secondary};
`;

const Subtle = styled.div`
  color: ${(p) => p.theme.colors.text.subtle};
`;

function DayRow({ tag }: { tag: string }) {
  const userPuzzle = useRecoilValue(userPuzzleForKey(tag));
  const date = keyToLocalDate(tag);
  const navigate = useNavigate();
  return (
    <DayRowWrapper onClick={() => navigate(`/game/${tag}`)}>
      <RegularText>{format(date, "MMM d, yyyy")}</RegularText>
      <Spacer />
      {userPuzzle !== undefined ? (
        <ArchiveStatus status={userPuzzle.submitted ? "completed" : "started"}>
          {userPuzzle.submitted ? "completed" : "started"}
        </ArchiveStatus>
      ) : undefined}
      <Subtle>
        <FaChevronRight />
      </Subtle>
      {/* </RegularText> */}
    </DayRowWrapper>
  );
}
