import { useRecoilValue } from "recoil";
import { PuzzleCellContent } from "../interactivePuzzle/InteractivePuzzleCell";
import { puzzleAtom, uniqueId } from "../selectors/puzzleSelectors";
import { tilesByIndex } from "../selectors/puzzleStateSelectors";
import { indexToKey, PuzzleIndex } from "../selectors/userPuzzleSelectors";
import PuzzleBoard from "./PuzzleBoard";
import ReadOnlyPuzzleCell from "./ReadOnlyPuzzleCell";

export default function CompletedPuzzle() {
  // const puzzleKey = `DailyPuzzle${puzzleId}`;
  const puzzle = useRecoilValue(puzzleAtom);
  const tiles = useRecoilValue(tilesByIndex);

  function content(index: PuzzleIndex): PuzzleCellContent {
    const tile = tiles[indexToKey(index)];
    if (!tile) return "empty";
    return { tile: tile, state: "complete" };
  }

  return (
    <PuzzleBoard board={puzzle.board} puzzleKey={uniqueId(puzzle)}>
      {(key, size, index) => {
        return (
          <ReadOnlyPuzzleCell
            key={key}
            size={size}
            content={content(index)}
          ></ReadOnlyPuzzleCell>
        );
      }}
    </PuzzleBoard>
  );
}
