import { Fragment, ReactNode, useState } from "react";
import GridwordModal from "./GridwordModal";

export default function ButtonModal({
  children,
  modal,
}: {
  children: ReactNode;
  modal: (hide: () => void) => ReactNode;
}) {
  const [show, setShow] = useState(false);
  return (
    <Fragment>
      <GridwordModal show={show}>{modal(() => setShow(false))}</GridwordModal>
      <div onClick={() => setShow(true)}>{children}</div>
    </Fragment>
  );
}
