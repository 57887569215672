import { TileStateMap, UserPuzzle } from "../selectors/userPuzzleSelectors";

export default function shuffleRackedTiles(current: UserPuzzle): UserPuzzle {
  const rackedTileKeys = Object.keys(current.map).flatMap((key) => {
    const item = current.map[key];
    if (item.rackIndex !== undefined) {
      return [key];
    }
    return [];
  });

  let changes: TileStateMap = {};
  rackedTileKeys
    .sort(() => Math.random() - 0.5)
    .forEach((key, i) => {
      changes[key] = { rackIndex: i };
    });
  return {
    ...current,
    map: {
      ...current.map,
      ...changes,
    },
  };
}
