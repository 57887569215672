import styled from "styled-components";

export const PrimaryButton = styled.button<{
  color?: string;
  disabled?: boolean;
}>`
  background: ${(p) =>
    p.disabled
      ? p.theme.colors.button.disabled
      : p.color || p.theme.colors.primary};
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  color: ${(p) =>
    p.disabled ? p.theme.colors.text.disabled : p.theme.colors.text.contrast};
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.m}px;
  font-weight: 600;
  user-select: none;

  &:active {
    opacity: 0.8;
  }
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
      cursor: ${(p) => (p.disabled ? "" : "pointer")};
    }
  }
`;

export const PrimaryButtonWarning = styled(PrimaryButton)`
  background: ${(p) => p.theme.colors.button.warning};
`;

export const PrimaryButtonLarge = styled(PrimaryButton)`
  padding: 16px 32px;
`;

export const PrimaryIconButton = styled(PrimaryButton)`
  padding: 13px 16px 8px;
`;
