import { Fragment, ReactNode } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import AppContainer from "../common/AppContainer";
import { RegularText } from "../common/CommonElements";
import { PrimaryButton } from "../common/PrimaryButton";
import LogIn from "./LogIn";
import { userSelector } from "./selectors/userSelectors";

export default function RequiresAuth({
  children,
  adminOnly,
}: {
  adminOnly?: boolean;
  children: ReactNode;
}) {
  const user = useRecoilValue(userSelector);

  if (!user) {
    return <LogIn></LogIn>;
  }
  if (adminOnly && !user.isAdmin) {
    return <NoPermission />;
  }
  return <Fragment>{children}</Fragment>;
}

function NoPermission() {
  const navigate = useNavigate();
  return (
    <AppContainer
      nav={<Fragment />}
      justifyContent="center"
      alignItems="center"
      fullHeight={true}
    >
      <RegularText>You don't have permission to view this page</RegularText>
      <br />
      <PrimaryButton onClick={() => navigate("/")}>Main Menu</PrimaryButton>
    </AppContainer>
  );
}
