import { ReactNode, useState } from "react";
import { FaInfoCircle, FaLink, FaTag } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import styled from "styled-components";
import { useConfirmAlert } from "../common/Alerts";
import ButtonModal from "../common/ButtonModal";
import {
  CloseButton,
  IconButton,
  Spacer,
  Subtitle,
  Title,
} from "../common/CommonElements";
import { useShareText } from "../puzzle/hooks/useShareText";
import { puzzleAtom, PuzzleModel } from "../puzzle/selectors/puzzleSelectors";
import { userPuzzleHistoryAtom } from "../puzzle/selectors/userPuzzleHistory";
import {
  keyForPuzzleSource,
  userPuzzleForKey,
  userPuzzleSelector,
} from "../puzzle/selectors/userPuzzleSelectors";
import { PuzzleName } from "./PuzzleNavBar";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 250px;
`;

export function PuzzleInfoButton() {
  return (
    <ButtonModal modal={(hide) => <PuzzleInfo onDone={hide} />}>
      <IconButton>
        <FaInfoCircle />
      </IconButton>
    </ButtonModal>
  );
}

const Actions = styled.div`
  & > * + * {
    margin-top: 8px;
  }
`;

const ActionWrapper = styled.div<{ clickable: boolean }>`
  margin-top: 20px;
  background: ${(p) => p.theme.colors.background.secondary};
  border-radius: 5px;
  padding: 16px;
  min-height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;

  &:active {
    opacity: ${(p) => (p.clickable ? 0.8 : 1)};
  }

  @media (hover: hover) {
    &:hover {
      opacity: ${(p) => (p.clickable ? 0.8 : 1)};
      cursor: ${(p) => (p.clickable ? "pointer" : "")};
    }
  }
`;

const ActionIcon = styled.div`
  color: ${(p) => p.theme.colors.text.subtle};
  font-size: ${(p) => p.theme.text.size.m}px;
  margin-right: 8px;
`;

function InfoRow({
  icon,
  title,
  children,
  onClick,
}: {
  onClick?: () => void;
  icon?: ReactNode;
  title: string;
  children: ReactNode;
}) {
  return (
    <ActionWrapper onClick={onClick} clickable={!!onClick}>
      {icon ? <ActionIcon>{icon}</ActionIcon> : undefined}
      <Subtitle>{title}</Subtitle>
      <Spacer></Spacer>
      {children}
    </ActionWrapper>
  );
}

const StackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  & > * + * {
    margin-top: 8px;
  }
`;

const Action = styled(Subtitle)`
  color: ${(p) => p.theme.colors.primary};
`;

function StackedAction({
  children,
  action,
}: {
  children: string;
  action: string;
}) {
  return (
    <StackWrapper>
      <Title>{children}</Title>
      <Action>{action}</Action>
    </StackWrapper>
  );
}

const SimpleActionWrapper = styled(ActionWrapper)`
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.colors.button.warning};
  min-height: inherit;
  padding: 14px;
`;

function SimpleAction({
  children,
  onClick,
}: {
  children: string;
  onClick: () => void;
}) {
  return (
    <SimpleActionWrapper clickable={true} onClick={onClick}>
      <Title>{children}</Title>
    </SimpleActionWrapper>
  );
}

function PuzzleInfo({ onDone }: { onDone: () => void }) {
  const puzzle = useRecoilValue(puzzleAtom);
  const [copiedLink, setCopiedLink] = useState(false);
  const link = linkTextForPuzzle(puzzle);
  const fullLink = `https://playgridword.com${link}`;
  const share = useShareText(fullLink, () => setCopiedLink(true));
  const puzzleKey = keyForPuzzleSource(puzzle.source);
  const [, setUserPuzzle] = useRecoilState(userPuzzleForKey(puzzleKey));
  const resetHistory = useResetRecoilState(userPuzzleHistoryAtom);
  const userPuzzle = useRecoilValue(userPuzzleSelector);

  const [confirmReset, showConfirmReset] = useConfirmAlert(
    "Are you sure you want to reset this puzzle?",
    () => {
      setUserPuzzle(undefined);
      resetHistory();
      setTimeout(() => {
        onDone();
      }, 100);
    },
    "Reset",
    "Reset"
  );

  return (
    <Wrapper>
      {confirmReset}
      <CloseButton onClick={onDone} />
      <PuzzleName></PuzzleName>
      <Actions>
        {puzzle.source.key === "daily" ? (
          <PracticeLinkRow tag={puzzle.source.tag} />
        ) : undefined}
        <InfoRow icon={<FaLink />} title="Link" onClick={share}>
          <StackedAction action={copiedLink ? "Copied!" : "Share >"}>
            {link}
          </StackedAction>
        </InfoRow>
        {userPuzzle.submitted && puzzle.source.key !== "daily" ? (
          <SimpleAction onClick={() => showConfirmReset(true)}>
            Reset
          </SimpleAction>
        ) : undefined}
      </Actions>
    </Wrapper>
  );
}

function PracticeLinkRow({ tag }: { tag: string }) {
  const navigate = useNavigate();
  return (
    <InfoRow
      onClick={() => navigate(`/game/${tag}`)}
      icon={<FaTag />}
      title="Tag"
    >
      <StackedAction action={"Practice >"}>{tag}</StackedAction>
    </InfoRow>
  );
}

function linkTextForPuzzle(puzzle: PuzzleModel): string {
  switch (puzzle.source.key) {
    case "daily":
      return `/game/${puzzle.source.key}`;
    case "tagged":
      return `/game/${puzzle.source.tag}`;
    case "none":
      return `_`;
  }
}
