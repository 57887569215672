import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";
import useDocumentTitle from "../../../global/hooks/useDocumentTitle";
import CompletedPuzzle from "../board/CompletedPuzzle";
import InteractivePuzzle from "../interactivePuzzle/InteractivePuzzle";
import { puzzleTitleShort } from "../selectors/puzzleSelectors";
import { userPuzzleSelector } from "../selectors/userPuzzleSelectors";
import GameFooter from "./footer/GameFooter";
import PuzzleDnd from "./PuzzleDnd";
import Results from "./Results";

// EXPECTS RECOIL puzzleAtom TO BE SET ALREADY
function PuzzleGame() {
  const state = useRecoilValue(userPuzzleSelector);
  const title = useRecoilValue(puzzleTitleShort);
  useDocumentTitle(`GridWord ${title}`);

  return (
    <PuzzleDnd>
      {state.submitted ? (
        <Fragment>
          <CompletedPuzzle></CompletedPuzzle>
          <Results></Results>
        </Fragment>
      ) : (
        <Fragment>
          <InteractivePuzzle></InteractivePuzzle>
          <GameFooter></GameFooter>
        </Fragment>
      )}
    </PuzzleDnd>
  );
}

export default PuzzleGame;
