import { useNavigate } from "react-router";
import { useCanGoBack } from "../../global/hooks/useMaybeBackButton";
import TutorialTips from "./tips/TutorialTips";

export default function TipsAndScoring() {
  const canGoBack = useCanGoBack();
  const navigate = useNavigate();
  const onClose = () => {
    if (canGoBack) navigate(-1);
    else navigate("/");
  };
  return (
    <TutorialTips
      key="tutorial-3"
      onBack={onClose}
      onClose={onClose}
      onNext={onClose}
    ></TutorialTips>
  );
}
