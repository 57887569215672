import { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import placeTile from "../actions/placeTile";
import PuzzleBoard from "../board/PuzzleBoard";
import useInteractiveCellTapped from "../hooks/useInteractiveCellTapped";
import usePuzzleKeyboardHandler from "../hooks/usePuzzleKeyboardHandler";
import { displayedTileStates } from "../selectors/displaySelectors";
import {
  selectedDirectionAtom,
  selectedIndexAtom,
} from "../selectors/puzzleSelectionSelectors";
import { puzzleAtom, uniqueId } from "../selectors/puzzleSelectors";
import { tilesByIndex } from "../selectors/puzzleStateSelectors";
import { useUpdateUserPuzzle } from "../selectors/userPuzzleHistory";
import { indexToKey, PuzzleIndex } from "../selectors/userPuzzleSelectors";
import InteractivePuzzleCell, {
  PuzzleCellAction,
  PuzzleCellContent,
} from "./InteractivePuzzleCell";

function equalIndexes(a: PuzzleIndex, b: PuzzleIndex) {
  return a.x === b.x && a.y === b.y;
}

// function useDisplayedTileState(): DisplayedTileState {
//   const puzzleIsValid = useRecoilValue(puzzleValid);
//   const wordsValid = useRecoilValue(allWordsValid);
//   if (puzzleIsValid) return "ready";
//   if (wordsValid) return "valid";
//   return "default";
// }

export default function InteractivePuzzle() {
  const puzzle = useRecoilValue(puzzleAtom);
  const board = puzzle.board;
  const [selectedIndex, setSelectedIndex] = useRecoilState(selectedIndexAtom);
  const direction = useRecoilValue(selectedDirectionAtom);
  const tiles = useRecoilValue(tilesByIndex);
  const tileStates = useRecoilValue(displayedTileStates);
  const updateUserPuzzle = useUpdateUserPuzzle();

  const cellTapped = useInteractiveCellTapped();

  usePuzzleKeyboardHandler();

  const handleAction = useCallback(
    (action: PuzzleCellAction) => {
      switch (action.key) {
        case "CellTapped":
          cellTapped(action.cell);
          break;
        case "TileDropped":
          updateUserPuzzle((current) =>
            placeTile(action.tile.tile.id, action.index, current)
          );
          setSelectedIndex(action.index);
      }
    },
    [cellTapped, updateUserPuzzle, setSelectedIndex]
  );

  function contentForCell(index: PuzzleIndex): PuzzleCellContent {
    const key = indexToKey(index);
    const tile = tiles[key];
    if (!tile) return "empty";
    return { tile: tile, state: tileStates[key] || "default" };
  }

  return (
    <PuzzleBoard board={board} puzzleKey={uniqueId(puzzle)}>
      {(key, size, index) => {
        const selection = equalIndexes(index, selectedIndex)
          ? direction
          : undefined;
        return (
          <InteractivePuzzleCell
            key={key}
            size={size}
            cellModel={{ index, content: contentForCell(index), selection }}
            board={board}
            onAction={handleAction}
          ></InteractivePuzzleCell>
        );
      }}
    </PuzzleBoard>
  );
}
