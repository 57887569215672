import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";
import { useConfirmAlert, useNotificationAlert } from "../../../common/Alerts";
import { PrimaryButton } from "../../../common/PrimaryButton";
import submit from "../../actions/submit";
import { puzzleScore } from "../../selectors/puzzleScoreSelectors";
import {
  allWordsValid,
  tileGroups,
} from "../../selectors/puzzleValidateSelectors";
import { boardWords } from "../../selectors/puzzleWordsSelectors";
import { useUpdateUserPuzzle } from "../../selectors/userPuzzleHistory";

export default function SubmitButton() {
  const groups = useRecoilValue(tileGroups);
  const words = useRecoilValue(boardWords);
  const wordsValid = useRecoilValue(allWordsValid);
  const score = useRecoilValue(puzzleScore);
  const updateUserPuzzle = useUpdateUserPuzzle();
  const [oneGroupAlert, showOneGroup] = useNotificationAlert(
    "Words must form a single group. Try connecting all the words!"
  );
  const [lowScoreAlert, showLowScore] = useNotificationAlert(
    "Your score is too low, try using more letters."
  );
  const [confirmSubmit, showConfirmSubmit] = useConfirmAlert(
    "Are you sure you want to submit and see your final score?",
    () => updateUserPuzzle(submit),
    "Submit",
    "Submit"
  );
  return (
    <Fragment>
      {lowScoreAlert}
      {confirmSubmit}
      {oneGroupAlert}
      <PrimaryButton
        disabled={words.length === 0 || !wordsValid}
        onClick={() => {
          if (groups.length > 1) {
            showOneGroup(true);
          } else if (score > 0) {
            showConfirmSubmit(true);
          } else {
            showLowScore(true);
          }
        }}
      >
        Submit
      </PrimaryButton>
    </Fragment>
  );
}
