import { selector } from "recoil";
import { DisplayedTileState } from "../types/displayedTile";
import { boardState, BoardStateMap } from "./puzzleStateSelectors";
import { puzzleValid } from "./puzzleValidateSelectors";
import { BoardWord, findWords } from "./puzzleWordsSelectors";
import { indexToKey, PuzzleIndex } from "./userPuzzleSelectors";

function indicesForWord(word: BoardWord): PuzzleIndex[] {
  return word.characters.split("").map((_, i) => {
    if (word.direction === "down") {
      return { x: word.start.x, y: word.start.y + i };
    }
    return { x: word.start.x + i, y: word.start.y };
  });
}

type IndexedWordInvolvment = { [puzzleIndex: string]: BoardWord[] };
function wordsPartOfMap(boardState: BoardStateMap): IndexedWordInvolvment {
  var words: IndexedWordInvolvment = {};
  findWords(boardState).forEach((word) => {
    indicesForWord(word).forEach((index) => {
      const key = indexToKey(index);
      if (!words[key]) words[key] = [];
      words[key].push(word);
    });
  });
  return words;
}

type DisplayedTileStateMap = { [puzleIndex: string]: DisplayedTileState };
export const displayedTileStates = selector<DisplayedTileStateMap>({
  key: "displayedTileStates",
  get: ({ get }) => {
    const tileMap = get(boardState);
    const indexedWords = wordsPartOfMap(tileMap);
    const puzzleIsValid = get(puzzleValid);
    let output: DisplayedTileStateMap = {};
    Object.keys(tileMap).forEach((index) => {
      const words = indexedWords[index];
      if (!words || words.length === 0) return;
      const countValid = words.filter((w) => w.valid).length;
      if (countValid === words.length) {
        output[index] = puzzleIsValid ? "ready" : "valid";
      } else {
        output[index] = "default";
      }
    });
    return output;
  },
});
