import styled from "styled-components";
import { Subtitle } from "../../common/CommonElements";

const Score = styled.div<{ color?: string }>`
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.h2}px;
  font-weight: ${(p) => p.theme.text.weight.regular};
  color: ${(p) => p.color || p.theme.colors.text.main};
`;

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScoreSubtitle = styled(Subtitle)<{ color?: string }>`
  color: ${(p) => p.color};
`;

export default function ScoreSummary({
  children,
  title,
  color,
}: {
  children: string;
  color?: string;
  title?: string;
}) {
  return (
    <ScoreWrapper>
      <ScoreSubtitle color={color}>{title || "Score"}</ScoreSubtitle>
      <Score color={color}>{children}</Score>
    </ScoreWrapper>
  );
}
