import { Fragment } from "react";
import styled from "styled-components";
import makeArray from "../../../global/utils/makeArray";
import { Header1, SmallText, Subtitle } from "../../common/CommonElements";
import { bonus } from "../../puzzle/selectors/puzzleScoreSelectors";
import { TipHeader } from "./TipComponents";

export default function Tip4() {
  return (
    <Fragment>
      <TipHeader>Bigger words, bigger bonuses</TipHeader>
      <BonusChart></BonusChart>
    </Fragment>
  );
}

const BonusWrapper = styled.div`
  /* flex: 1; */
  padding-bottom: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  & > * + * {
    margin-top: 20px;
  }
`;

const Bonuses = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const BonusHeaderCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-right: 1px solid ${(p) => p.theme.colors.text.subtle};
  padding-right: 12px;
  margin-right: 12px;

  & > * + * {
    margin-top: 8px;
  }
`;
const BonusCell = styled.div`
  padding: 0 5px;
  text-align: center;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 8px;
  }
`;

function BonusChart() {
  const array = makeArray(8);
  return (
    <BonusWrapper>
      <Header1>Bonus Chart</Header1>
      <Bonuses>
        <BonusHeaderCell>
          <Subtitle>Word Length</Subtitle>
          <Subtitle>Bonus</Subtitle>
        </BonusHeaderCell>
        {array.map((i) => {
          const last = i === array.length - 1;
          const wordLength = i + 4;
          const wordBonus = bonus(wordLength);
          return (
            <BonusCell key={`bonusfor-${wordLength}`}>
              <SmallText>{last ? "..." : wordLength}</SmallText>
              <SmallText>{last ? "..." : wordBonus}</SmallText>
            </BonusCell>
          );
        })}
      </Bonuses>
    </BonusWrapper>
  );
}
