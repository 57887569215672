import { QueryDocumentSnapshot } from "firebase/firestore";

export default function buildRawConverter<T>() {
  return {
    toFirestore: (data: T) => {
      return data;
    },
    fromFirestore: (snap: QueryDocumentSnapshot): T => {
      return snap.data() as T;
    },
  };
}
