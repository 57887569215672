import { useEffect } from "react";
import { Navigate } from "react-router";
import { auth } from "../../global/Firebase";

export default function SignOut() {
  useEffect(() => {
    auth.signOut();
  });
  return <Navigate to="/" />;
}
