import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot, useRecoilValue } from "recoil";
import { ThemeProvider } from "styled-components";
import "./App.css";
import DailyArchive from "./components/archive/DailyArchive";
import SignOut from "./components/auth/SignOut";
import { AppLoading } from "./components/common/AppContainer";
import useAutoDarkMode from "./components/common/useAutoDarkMode";
import Queue from "./components/dailyQueue/DailyQueue";
import MainMenu from "./components/mainMenu/MainMenu";
import NotFound from "./components/notFound/NotFound";
import OpenPuzzle from "./components/openPuzzle/OpenPuzzle";
import PuzzleForId from "./components/puzzlePage/PuzzleForId";
import PuzzleGameDaily from "./components/puzzlePage/PuzzleGameDaily";
import SelectRandom from "./components/selectRandom/SelectRandom";
import TipsAndScoring from "./components/tutorial/TipsAndScoring";
import Tutorial from "./components/tutorial/Tutorial";
import { useReactNativeListenForMessages } from "./global/hooks/useReactNativeMessage";
import { darkModeSelector } from "./global/selectors/DarkModeAtom";
import { darkModeTheme, lightModeTheme } from "./global/styleGuide";

function ErrorFallback({ error }: { error: Error | undefined }) {
  return (
    <div>
      Error occured {error?.message} {error?.stack}
    </div>
  );
}

function App() {
  const [err, setErr] = useState<Error | undefined>();
  if (err) return <ErrorFallback error={err} />;
  return (
    <RecoilRoot>
      <ErrorBoundary
        onError={(e) => setErr(e)}
        FallbackComponent={ErrorFallback}
      >
        <ThemedApp></ThemedApp>
      </ErrorBoundary>
    </RecoilRoot>
  );
}

function ThemedApp() {
  const darkMode = useRecoilValue(darkModeSelector);
  useAutoDarkMode();
  useReactNativeListenForMessages();

  return (
    <ThemeProvider theme={darkMode ? darkModeTheme : lightModeTheme}>
      <BrowserRouter>
        <React.Suspense fallback={<AppLoading />}>
          <Routes>
            <Route index element={<MainMenu />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/open" element={<OpenPuzzle />} />
            <Route path="/random" element={<SelectRandom />} />
            <Route path="/game">
              <Route index element={<PuzzleGameDaily />} />
              <Route path=":puzzleId" element={<PuzzleForId />} />
            </Route>
            <Route path="/queue" element={<Queue />} />
            <Route path="/queue/:page" element={<Queue />} />
            <Route path="/tips" element={<TipsAndScoring />} />
            <Route path="/archive" element={<DailyArchive />} />
            <Route path="/signOut" element={<SignOut />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
