import { usePreview } from "react-dnd-preview";
import styled from "styled-components";
import CharacterTile from "../board/CharacterTile";

const Wrapper = styled.div`
  z-index: 100;
`;

export default function FloatingTile() {
  const { display, item, style } = usePreview();
  if (!display) {
    return null;
  }
  return (
    <Wrapper style={style}>
      <CharacterTile size={44} tile={item}></CharacterTile>
    </Wrapper>
  );
}
