import {
  indexToKey,
  PuzzleIndex,
  TileStateMap,
  UserPuzzle,
} from "../selectors/userPuzzleSelectors";

export default function placeTile(
  tileId: string,
  index: PuzzleIndex,
  current: UserPuzzle
): UserPuzzle {
  const all = Object.keys(current.map).map((k) => ({
    id: k,
    state: current.map[k],
  }));
  const indexKey = indexToKey(index);
  const existingId = all.find((item) => {
    if (!item.state.boardIndex) return false;
    return indexToKey(item.state.boardIndex) === indexKey;
  })?.id;
  let newMap: TileStateMap = {
    ...current.map,
    [tileId]: { boardIndex: index },
  };
  if (existingId) {
    const swapIndex = current.map[tileId]?.boardIndex;
    if (swapIndex) {
      newMap[existingId] = { boardIndex: swapIndex };
    } else {
      const maxIndex =
        Math.max(...all.map((item) => item.state.rackIndex || 0)) || 0;
      newMap[existingId] = { rackIndex: maxIndex + 1 };
    }
  }
  return {
    ...current,
    map: newMap,
  };
}
