import React, { Fragment, useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useRecoilCallback } from "recoil";
import styled from "styled-components";
import useDocumentTitle from "../../global/hooks/useDocumentTitle";
import AppContainer, { NavTitle } from "../common/AppContainer";
import { RegularTextError, Spacer } from "../common/CommonElements";
import { PrimaryButtonLarge } from "../common/PrimaryButton";
import { puzzleForTag } from "../puzzle/selectors/puzzleSelectors";
import { validChars61 } from "../puzzle/selectors/randomPuzzleTag";
import { MenuOrBackButton } from "../puzzlePage/PuzzleNavBar";

// const OpenPuzzleContainer = styled.div`
//   padding: 20px;
//   flex: 1;
//   justify-content: start;
//   align-items: center;
//   display: flex;
//   flex-direction: column;
// `;
const OpenPuzzleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: start;
  width: 100%;
  max-width: 340px;
  box-sizing: border-box;
  padding: 16px;

  & > * + * {
    margin-top: 16px;
  }
`;

const OpenPuzzleInput = styled.input<{ error?: string }>`
  background-color: ${(p) => p.theme.colors.background.tertiary};
  /* border: 1px solid ${(p) => p.theme.colors.background.tileDefault}; */
  border: ${(p) =>
    p.error ? `1px solid ${p.theme.colors.button.warning}` : "none"};
  padding: 16px;
  color: ${(p) => p.theme.colors.text.main};
  font-family: ${(p) => p.theme.text.family.primary};
  font-size: ${(p) => p.theme.text.size.l}px;
  font-weight: ${(p) => p.theme.text.weight.medium};
  outline: none;
  display: block;
  box-sizing: border-box;
  max-width: 400px;
  pointer-events: none;
  border-radius: 5px;
  width: 100%;

  ::placeholder {
    color: ${(p) => p.theme.colors.text.subtle};
  }
`;

const LoadingSpinner = styled(FaSpinner)`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: spinner 1.5s linear infinite;
  font-size: ${(p) => p.theme.text.size.m}px;
`;

export default function OpenPuzzle() {
  // const [text, setText]
  const [text, setText] = useState("");
  const [error, setError] = useState<undefined | string>();
  const [loading, setLoading] = useState(false);
  const focusDiv = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  useDocumentTitle(`GridWord - Open Puzzle`);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.toLowerCase();
    setError(undefined);
    if (value.length === text.length + 1) {
      const newChar = value.charAt(value.length - 1);
      if (!validChars61(newChar)) return;
      else if (value.length === 3) {
        setText(value + "-");
      } else if (value.length === 9) {
        return;
      } else {
        setText(value);
      }
    } else if (value.length < text.length) {
      // if (value.length === 4) setText(value.slice(0, 3));
      if (value.length === 3) setText(value.slice(0, 2));
      else {
        setText(value);
      }
    }
  };

  useEffect(() => {
    if (focusDiv.current) focusDiv.current.focus();
  }, [focusDiv]);

  const navigate = useNavigate();

  const searchPuzzle = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        setLoading(true);
        const release = snapshot.retain();
        snapshot
          .getPromise(puzzleForTag(text))
          .then(() => {
            navigate(`/game/${text}`);
            release();
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setError("Puzzle not found");
            focusDiv.current.focus();
            release();
          });
      },
    [text]
  );

  return (
    <AppContainer
      nav={<OpenPuzzleHeader />}
      justifyContent="start"
      alignItems="center"
      onClick={() => focusDiv.current.focus()}
    >
      <OpenPuzzleContent>
        <RegularTextError>{error}</RegularTextError>
        <OpenPuzzleInput
          error={error}
          disabled={loading}
          ref={focusDiv}
          autoFocus
          value={text}
          onKeyDown={(e) => {
            if (
              e.key === "ArrowLeft" ||
              e.key === "ArrowRight" ||
              e.key === "ArrowUp" ||
              e.key === "ArrowDown"
            )
              e.preventDefault();

            if (e.key === "Enter") searchPuzzle();
          }}
          onChange={handleChange}
          placeholder="Enter Puzzle Tag"
        ></OpenPuzzleInput>
        <PrimaryButtonLarge disabled={loading} onClick={searchPuzzle}>
          {loading ? <LoadingSpinner /> : "Play"}
        </PrimaryButtonLarge>
      </OpenPuzzleContent>
    </AppContainer>
  );
}

function OpenPuzzleHeader() {
  return (
    <Fragment>
      <MenuOrBackButton />
      <NavTitle>Open Puzzle</NavTitle>
      <Spacer></Spacer>
    </Fragment>
  );
}
