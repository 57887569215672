import { selector } from "recoil";
import { puzzleScore } from "./puzzleScoreSelectors";
import { TileModel } from "./puzzleSelectors";
import { tilesByIndex } from "./puzzleStateSelectors";
import { boardWords } from "./puzzleWordsSelectors";
import { indexToKey, keyToIndex, PuzzleIndex } from "./userPuzzleSelectors";

export const tileGroups = selector<TileModel[][]>({
  key: "tileGroups",
  get: ({ get }) => {
    const tileMap = get(tilesByIndex);
    const visited: { [key: string]: true } = {};
    const groups: TileModel[][] = [];
    Object.keys(tileMap).forEach((key) => {
      if (visited[key]) return;
      var queue: PuzzleIndex[] = [];
      var group: TileModel[] = [];
      queue.push(keyToIndex(key));
      while (queue.length > 0) {
        const cur = queue.pop()!;
        key = indexToKey(cur);
        group.push(tileMap[key]);
        const directions = [
          [0, 1],
          [1, 0],
          [0, -1],
          [-1, 0],
        ];
        directions.forEach((d) => {
          const loc: PuzzleIndex = {
            x: cur.x + d[0],
            y: cur.y + d[1],
          };
          const key = indexToKey(loc);
          if (visited[key]) return;
          const tile = tileMap[key];
          if (tile) {
            queue.push(loc);
            visited[key] = true;
          }
        });
      }
      groups.push(group);
    });
    return groups;
  },
});

export const allWordsValid = selector<boolean>({
  key: "allWordsValid",
  get: ({ get }) => {
    const words = get(boardWords);
    return !words.find((w) => !w.valid);
  },
});

export const puzzleValid = selector<boolean>({
  key: "puzzleValid",
  get: ({ get }) => {
    const validWords = get(allWordsValid);
    const groups = get(tileGroups);
    const score = get(puzzleScore);
    return validWords && groups.length === 1 && score > 0;
  },
});
