import { Fragment, ReactNode, useEffect, useState } from "react";
import {
  RecoilRoot,
  useRecoilStateLoadable,
  useResetRecoilState,
} from "recoil";
import { AppLoading } from "../../common/AppContainer";
import {
  selectedDirectionAtom,
  selectedIndexAtom,
} from "../selectors/puzzleSelectionSelectors";
import { puzzleAtom, PuzzleModel } from "../selectors/puzzleSelectors";
import { userPuzzleHistoryAtom } from "../selectors/userPuzzleHistory";

function PuzzleStateInner({
  puzzle,
  children,
  fallback,
}: {
  fallback?: ReactNode;
  puzzle: PuzzleModel;
  children: ReactNode;
}) {
  const [loaded, setLoaded] = useState(false);
  const [, setPuzzle] = useRecoilStateLoadable(puzzleAtom);
  const resetPuzzleHistory = useResetRecoilState(userPuzzleHistoryAtom);
  const resetDirection = useResetRecoilState(selectedDirectionAtom);
  const resetSelection = useResetRecoilState(selectedIndexAtom);

  useEffect(() => {
    setPuzzle(puzzle);
    resetPuzzleHistory();
    resetSelection();
    resetDirection();
    setLoaded(true);
  }, [puzzle, resetPuzzleHistory, setPuzzle, resetSelection, resetDirection]);

  if (!loaded && fallback) return <Fragment>{fallback}</Fragment>;
  if (!loaded) return <AppLoading />;
  return <Fragment>{children}</Fragment>;
}

export default function PuzzleStateWrapper({
  puzzle,
  children,
  fallback,
}: {
  puzzle: PuzzleModel;
  children: ReactNode;
  fallback?: ReactNode;
}) {
  return (
    <RecoilRoot override={false}>
      <PuzzleStateInner fallback={fallback} puzzle={puzzle}>
        {children}
      </PuzzleStateInner>
    </RecoilRoot>
  );
}
