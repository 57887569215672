import { format } from "date-fns";
import { Fragment } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import styled, { useTheme } from "styled-components";
import RequiresAuth from "../auth/RequiresAuth";
import AppContainer, { NavTitle } from "../common/AppContainer";
import { IconButton, RegularText } from "../common/CommonElements";
import { PrimaryButtonWarning } from "../common/PrimaryButton";
import EmptyBoard from "../puzzle/board/EmptyBoard";
import {
  PuzzleModel,
  titleForPuzzle,
  uniqueId,
} from "../puzzle/selectors/puzzleSelectors";
import TilesList from "../selectRandom/TilesList";
import {
  dailyQueueAtom,
  keyToLocalDate,
  puzzleQueuePageSelector,
} from "./selectors/dailyQueueSelectors";

const QueueWrapper = styled.div`
  background: ${(p) => p.theme.colors.background.board};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
`;

export default function DailyQueue() {
  const params = useParams();
  const pageNumber = (parseInt(params.page as string) || 1) - 1;
  return (
    <RequiresAuth adminOnly={true}>
      <AuthenticatedQueue pageNumber={pageNumber} />
    </RequiresAuth>
  );
}

function AuthenticatedQueue({ pageNumber }: { pageNumber: number }) {
  const page = useRecoilValue(puzzleQueuePageSelector(pageNumber));
  const puzzles = page.puzzles;

  return (
    <AppContainer nav={<DailyQueueNav />} justifyContent="center">
      <QueueWrapper>
        <PageSelection current={pageNumber}></PageSelection>
        {puzzles.map((puzzle, i) => {
          return (
            <PuzzlePreview
              canDelete={i >= page.alreadyCreatedCount}
              key={uniqueId(puzzle)}
              puzzle={puzzle}
              queueIndex={page.queueStartIndex + i - page.alreadyCreatedCount}
            />
          );
        })}
        <PageSelection current={pageNumber}></PageSelection>
      </QueueWrapper>
    </AppContainer>
  );
}

const PageSelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > * + * {
    margin-left: 10px;
  }
`;

function PageSelection({ current }: { current: number }) {
  const navigate = useNavigate();
  return (
    <PageSelectionWrapper>
      <IconButton
        onClick={() => navigate(`/queue/${current}`)}
        disabled={current < 1}
      >
        <FaChevronLeft />
      </IconButton>
      <RegularText>Page: {current + 1}</RegularText>
      <IconButton onClick={() => navigate(`/queue/${current + 2}`)}>
        <FaChevronRight />
      </IconButton>
    </PageSelectionWrapper>
  );
}

function DailyQueueNav() {
  return (
    <Fragment>
      <NavTitle>Upcoming Puzzles</NavTitle>
    </Fragment>
  );
}

const PuzzleWrapper = styled.div`
  margin: 20px auto;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid ${(p) => p.theme.colors.background.tertiary};
  box-sizing: border-box;

  & > * + * {
    margin-top: 16px;
  }
`;

const PuzzleBottomSection = styled.div`
  /* max-height: 80px; */
`;

const PuzzleTopSection = styled.div`
  display: flex;
  flex-direction: row;
  height: 400px;
`;
const PuzzleDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`;

function PuzzlePreview({
  puzzle,
  canDelete,
  queueIndex,
}: {
  puzzle: PuzzleModel;
  canDelete: boolean;
  queueIndex: number;
}) {
  const date = dateForPuzzle(puzzle);
  const theme = useTheme();
  const [, setQueue] = useRecoilState(dailyQueueAtom);
  return (
    <PuzzleWrapper>
      <PuzzleDetails>
        {date ? (
          <RegularText>{format(date, "M/d/yyyy")}</RegularText>
        ) : undefined}
        <RegularText>{titleForPuzzle(puzzle)}</RegularText>
      </PuzzleDetails>
      <PuzzleTopSection>
        <EmptyBoard board={puzzle.board} id={uniqueId(puzzle)}></EmptyBoard>
      </PuzzleTopSection>
      <PuzzleBottomSection>
        <TilesList tiles={puzzle.tiles}></TilesList>
      </PuzzleBottomSection>
      {canDelete ? (
        <PrimaryButtonWarning
          color={theme.colors.button.warning}
          onClick={() => {
            setQueue((current) => {
              if (!current) return;
              let queue = [...current.queue];
              queue.splice(queueIndex, 1);
              return {
                ...current,
                queue,
              };
            });
          }}
        >
          Delete
        </PrimaryButtonWarning>
      ) : undefined}
    </PuzzleWrapper>
  );
}

function dateForPuzzle(puzzle: PuzzleModel): Date | undefined {
  if (puzzle.source.key === "daily") return keyToLocalDate(puzzle.source.day);
  return undefined;
}
