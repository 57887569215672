import styled from "styled-components";
import { CloseButton, Header1 } from "../../common/CommonElements";

const HeaderWrapper = styled.div<{ showBackground?: boolean }>`
  position: relative;
  align-items: center;
  padding: 30px 30px;
  background: ${(p) =>
    p.showBackground ? p.theme.colors.background.main : ""};
`;

const HeaderContent = styled.div`
  position: relative;
`;

export default function TutorialHeader({
  showBackground,
  children,
  onClose,
}: {
  showBackground?: boolean;
  children: string;
  onClose: () => void;
}) {
  return (
    <HeaderWrapper showBackground={showBackground}>
      <HeaderContent>
        <Header1>{children}</Header1>
        <CloseButton onClick={onClose}></CloseButton>
      </HeaderContent>
    </HeaderWrapper>
  );
}
