import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";
import DimensionComponent, {
  ViewSize,
} from "../../../common/DimensionComponent";
import moveToEndOfRack from "../../actions/moveToEndOfRack";
import { useUpdateUserPuzzle } from "../../selectors/userPuzzleHistory";
import { DisplayedTile } from "../../types/displayedTile";
import { DragTypes } from "../../types/dragTypes";
import TileRack from "../tileRack/TileRack";
import FooterActions from "./FooterActions";

const FooterWrapper = styled.div<{ highlight: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${(p) =>
    p.highlight
      ? p.theme.colors.background.board
      : p.theme.colors.background.main};
`;

export default function GameFooter() {
  const [viewSize, setSize] = useState<ViewSize>({ width: 100, height: 100 });
  const updateUserPuzzle = useUpdateUserPuzzle();
  const [, drop] = useDrop(
    () => ({
      accept: DragTypes.tile,
      drop: (item: DisplayedTile) => {
        updateUserPuzzle((puzzle) => moveToEndOfRack(item.tile.id, puzzle));
      },
      canDrop: (item, monitor) => {
        return monitor.isOver({ shallow: true });
      },
    }),
    [updateUserPuzzle]
  );

  useEffect(() => {
    // fire event to make sure board resizes...
    const event = new Event("resize");
    window.dispatchEvent(event);
  }, []);

  return (
    <FooterWrapper ref={drop} highlight={false}>
      <DimensionComponent buffer={300} onChange={setSize} />
      <TileRack containerWidth={viewSize.width}></TileRack>
      <FooterActions></FooterActions>
    </FooterWrapper>
  );
}
