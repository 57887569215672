import { useEffect } from "react";
import { useRecoilState } from "recoil";
import subscribeToKeyPress from "../../common/subscribeToKeyPress";
import { selectedDirectionAtom } from "../selectors/puzzleSelectionSelectors";
import useBackspace from "./useBackspace";
import useCharacterTapped from "./useKeyboardCharacterTapped";
import useMoveSelectedIndex from "./useMoveSelectedIndex";

export default function usePuzzleKeyboardHandler() {
  const [, setDirection] = useRecoilState(selectedDirectionAtom);
  const backspace = useBackspace();
  const moveLocation = useMoveSelectedIndex();
  const characterTapped = useCharacterTapped();

  useEffect(() => {
    return subscribeToKeyPress((key) => {
      switch (key) {
        case "ArrowRight":
        case "ArrowLeft":
          if (key === "ArrowRight") {
            moveLocation(1, 0);
          } else {
            moveLocation(-1, 0);
          }
          setDirection("right");
          break;
        case "ArrowDown":
        case "ArrowUp":
          if (key === "ArrowDown") {
            moveLocation(0, 1);
          } else {
            moveLocation(0, -1);
          }
          setDirection("down");
          break;
        case "Backspace":
          backspace();
          break;
        default:
          characterTapped(key.toLowerCase());
          break;
      }
    });
  }, [backspace, characterTapped, moveLocation, setDirection]);
}
