import { doc, getDoc, QueryDocumentSnapshot } from "firebase/firestore";
import { atom, selector, selectorFamily } from "recoil";
import { db } from "../../../global/Firebase";
import buildRawConverter from "../../../global/utils/buildRawConverter";

export const puzzleAtom = atom<PuzzleModel>({
  key: "puzzle",
});

export type ValidCharacter =
  | "a"
  | "b"
  | "c"
  | "d"
  | "e"
  | "f"
  | "g"
  | "h"
  | "i"
  | "j"
  | "k"
  | "l"
  | "m"
  | "n"
  | "o"
  | "p"
  | "q"
  | "r"
  | "s"
  | "t"
  | "u"
  | "v"
  | "w"
  | "x"
  | "y"
  | "z";

export const characterValues = {
  a: 1,
  b: 3,
  c: 3,
  d: 2,
  e: 1,
  f: 4,
  g: 2,
  h: 4,
  i: 1,
  j: 8,
  k: 5,
  l: 1,
  m: 3,
  n: 1,
  o: 1,
  p: 3,
  q: 10,
  r: 1,
  s: 1,
  t: 1,
  u: 1,
  v: 4,
  w: 4,
  x: 8,
  y: 4,
  z: 10,
};

export function pointsForCharacter(character: ValidCharacter): number {
  return characterValues[character];
}

export type BoardModel = boolean[][];

export interface TileModel {
  character: string;
  points: number;
  id: string;
}

interface PuzzleSourceBase {
  key: string;
}

interface PuzzleSourceDaily extends PuzzleSourceBase {
  key: "daily";
  day: string;
  index: number;
  tag: string;
}

interface PuzzleSourceTag extends PuzzleSourceBase {
  key: "tagged";
  tag: string;
  boardId: string;
  tilesetId: string;
}

interface PuzzleSourceNone extends PuzzleSourceBase {
  key: "none";
  id: string;
}

export type PuzzleSource =
  | PuzzleSourceDaily
  | PuzzleSourceTag
  | PuzzleSourceNone;

export interface PuzzleModel {
  source: PuzzleSource;
  board: BoardModel;
  tiles: TileModel[];
}

const Collections = {
  dayPuzzles: "dayPuzzles",
  userPuzzleStates: "userPuzzleStates",
  boardBank: "boardBank",
  tileBank: "tileBank",
};

const dayPuzzleConverter = {
  toFirestore: (data: PuzzleModel) => ({}),
  fromFirestore: (snap: QueryDocumentSnapshot): PuzzleModel => {
    const data = snap.data();
    const tileData = data.tiles as string;
    const boardData = data.board as string;
    const result: PuzzleModel = {
      board: buildBoard(boardData),
      tiles: buildTiles(tileData),
      source: {
        key: "daily",
        day: snap.id,
        index: data.index,
        tag: data.source || "",
      },
    };
    return result;
  },
};

export function buildTiles(characters: string): TileModel[] {
  return characters
    .toLowerCase()
    .split("")
    .map((character, i) => ({
      character: character,
      points: pointsForCharacter(character as ValidCharacter),
      id: `T${i}`,
    }));
}

export function buildBoard(input: string): BoardModel {
  return input
    .split(" ")
    .map((row) => row.split("").map((character) => character === "X"));
}

export const puzzleForDay = selectorFamily<PuzzleModel, string>({
  key: "puzzleForDay",
  get: (dayKey) => async () => {
    const ref = doc(db, Collections.dayPuzzles, dayKey).withConverter(
      dayPuzzleConverter
    );

    const snap = await getDoc(ref);
    if (snap.exists()) {
      return snap.data();
    } else {
      throw new Error("No data for date");
    }
  },
});

interface BoardRecord {
  board: string;
  columns: number;
  index: number;
  key: string;
  rows: number;
  squares: number;
}

interface TilesetRecord {
  index: number;
  key: string;
  length: number;
  tiles: string;
}

/**
 * Restrict tile count to be basically between 60% and 90%
 * of the number of squares on the board
 * "Randomized" using index of tileset, so will always return
 * the same number in combination with the board
 */

export function tilesForBoard(
  board: BoardRecord,
  tileset: TilesetRecord
): string {
  const tilesIndex = tileset.index;
  const baseTileCount = Math.floor(board.squares * 0.6);
  const maxAddTiles = Math.ceil(board.squares * 0.3) + 1;
  const reducedTilesCount = Math.min(
    baseTileCount + (tilesIndex % maxAddTiles),
    tileset.tiles.length
  );
  return tileset.tiles.slice(0, reducedTilesCount);
}

export const boardForTag = selectorFamily<BoardRecord, string>({
  key: "boardForTag",
  get: (tag) => async () => {
    const boardRef = doc(db, Collections.boardBank, tag).withConverter(
      buildRawConverter<BoardRecord>()
    );
    const board = (await getDoc(boardRef)).data();
    if (!board) throw new Error("Unable to find board");
    return board;
  },
});

export const tilesetForTag = selectorFamily<TilesetRecord, string>({
  key: "boardForTag",
  get: (tag) => async () => {
    const tilesRef = doc(db, Collections.tileBank, tag).withConverter(
      buildRawConverter<TilesetRecord>()
    );
    const tiles = (await getDoc(tilesRef)).data();
    if (!tiles) throw new Error("Unable to find tiles");
    return tiles;
  },
});

export const puzzleForTag = selectorFamily<PuzzleModel, string>({
  key: "puzzleForTag",
  get:
    (tag) =>
    async ({ get }) => {
      const comps = tag.split("-");
      const tilesKey = comps[0];
      const boardKey = comps[1];
      if (!tilesKey || !boardKey) throw new Error("Invalid tag");

      const board = get(boardForTag(boardKey));
      const tiles = get(tilesetForTag(tilesKey));

      return {
        board: buildBoard(board.board),
        tiles: buildTiles(tilesForBoard(board, tiles)),
        source: {
          key: "tagged",
          tag: tag,
          boardId: boardKey,
          tilesetId: tilesKey,
        },
      };
    },
});

export const puzzleTitleShort = selector({
  key: "puzzleTitleShort",
  get: ({ get }) => {
    const puzzle = get(puzzleAtom);
    switch (puzzle.source.key) {
      case "daily":
        return `#${puzzle.source.index}`;
      case "tagged":
        return `#${puzzle.source.tag}`;
      case "none":
        return puzzle.source.id;
    }
  },
});

export function titleForPuzzle(puzzle: PuzzleModel) {
  switch (puzzle.source.key) {
    case "daily":
      return `Daily #${puzzle.source.index}`;
    case "tagged":
      return `Tag #${puzzle.source.tag}`;
    case "none":
      return puzzle.source.id;
  }
}

export const puzzleTitle = selector({
  key: "puzzleTitle",
  get: ({ get }) => {
    const puzzle = get(puzzleAtom);
    return titleForPuzzle(puzzle);
  },
});

export function uniqueId(puzzle: PuzzleModel): string {
  switch (puzzle.source.key) {
    case "daily":
      return `DAY:${puzzle.source.day}`;
    case "tagged":
      return `TAG:${puzzle.source.tag}`;
    case "none":
      return puzzle.source.id;
  }
}

export const puzzleId = selector({
  key: "puzzleId",
  get: ({ get }) => {
    return uniqueId(get(puzzleAtom));
  },
});
