import { atom, useRecoilCallback, useRecoilValue } from "recoil";
import { puzzleAtom } from "./puzzleSelectors";
import {
  keyForPuzzleSource,
  puzzleKey,
  UserPuzzle,
  userPuzzleForKey,
  userPuzzleSelector,
} from "./userPuzzleSelectors";

type UserPuzzleHistory = {
  past: UserPuzzle[];
  future: UserPuzzle[];
};

export const userPuzzleHistoryAtom = atom<UserPuzzleHistory>({
  key: "userPuzzleHistoryAtom",
  default: { past: [], future: [] },
});

export function useUpdateUserPuzzle(): (
  action: (current: UserPuzzle) => UserPuzzle
) => void {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      (action: (current: UserPuzzle) => UserPuzzle) => {
        const userPuzzle = snapshot
          .getLoadable(userPuzzleSelector)
          .valueOrThrow();
        const puzzleHistory = snapshot
          .getLoadable(userPuzzleHistoryAtom)
          .valueOrThrow();
        const puzzle = snapshot.getLoadable(puzzleAtom).valueOrThrow();
        const key = keyForPuzzleSource(puzzle.source);

        const newHistory = {
          past: [...puzzleHistory.past, userPuzzle],
          future: [],
        };
        set(userPuzzleHistoryAtom, newHistory);
        set(userPuzzleForKey(key), action(userPuzzle));
      }
  );
}

export function useUndoUserPuzzle(): {
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
} {
  const userPuzzleHistory = useRecoilValue(userPuzzleHistoryAtom);
  return {
    undo: useRecoilCallback(({ set, snapshot }) => () => {
      const userPuzzle = snapshot
        .getLoadable(userPuzzleSelector)
        .valueOrThrow();
      const puzzleHistory = snapshot
        .getLoadable(userPuzzleHistoryAtom)
        .valueOrThrow();
      const key = snapshot.getLoadable(puzzleKey).valueOrThrow();
      const target = puzzleHistory.past[puzzleHistory.past.length - 1];
      if (!target) return;
      const newHistory = {
        past: [...puzzleHistory.past.slice(0, puzzleHistory.past.length - 1)],
        future: [userPuzzle, ...puzzleHistory.future],
      };
      set(userPuzzleHistoryAtom, newHistory);
      set(userPuzzleForKey(key), target);
    }),

    redo: useRecoilCallback(({ set, snapshot }) => () => {
      const userPuzzle = snapshot
        .getLoadable(userPuzzleSelector)
        .valueOrThrow();
      const puzzleHistory = snapshot
        .getLoadable(userPuzzleHistoryAtom)
        .valueOrThrow();
      const key = snapshot.getLoadable(puzzleKey).valueOrThrow();
      const target = puzzleHistory.future[0];
      if (!target) return;
      const newHistory = {
        past: [...puzzleHistory.past, userPuzzle],
        future: [...puzzleHistory.future.slice(1)],
      };
      set(userPuzzleHistoryAtom, newHistory);
      set(userPuzzleForKey(key), target);
    }),

    canRedo: userPuzzleHistory.future.length > 0,

    canUndo: userPuzzleHistory.past.length > 0,
  };
}
