import React, { ReactNode } from "react";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import FloatingTile from "../interactivePuzzle/FloatingTile";

export default function PuzzleDnd({ children }: { children: ReactNode }) {
  const options = {
    enableMouseEvents: true,
    enableHoverOutsideTarget: true,
    ignoreContextMenu: true,
  };
  return (
    <DndProvider backend={TouchBackend} options={options}>
      <FloatingTile></FloatingTile>
      {children}
    </DndProvider>
  );
}
