import React from "react";
import { BsFillGearFill } from "react-icons/bs";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { useReactNativeSendMessage } from "../../global/hooks/useReactNativeMessage";
import {
  darkModeAtom,
  darkModeSelector,
} from "../../global/selectors/DarkModeAtom";
import ButtonModal from "../common/ButtonModal";
import {
  CloseButton,
  Header2,
  IconButton,
  RegularText,
} from "../common/CommonElements";
import Toggle from "../common/Toggle";

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 250px;
`;

const SettingsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export function SettingsButton() {
  return (
    <ButtonModal modal={(hide) => <SettingsModal onDone={hide} />}>
      <IconButton>
        <BsFillGearFill></BsFillGearFill>
      </IconButton>
    </ButtonModal>
  );
}

export function SettingsModal({ onDone }: { onDone: () => void }) {
  const darkModeEnabled = useRecoilValue(darkModeSelector);
  const [, setDarkMode] = useRecoilState(darkModeAtom);

  const dev = process.env.NODE_ENV === "development";

  const sendMessage = useReactNativeSendMessage();

  return (
    <SettingsWrapper>
      <CloseButton onClick={onDone} />
      <Header2>Settings</Header2>
      <SettingsRow>
        <RegularText>Dark Mode</RegularText>
        <Toggle
          on={darkModeEnabled}
          setOn={(enabled) => {
            setDarkMode(enabled);
            sendMessage({ key: "darkMode", enabled: enabled });
          }}
        ></Toggle>
      </SettingsRow>
      {/* {dev ? (
          <button
            onClick={() => {
              const today = new Date();
              const newDay = addDays(today, -1);
              changeDay(newDay);
            }}
          >
            Do yesterday's
          </button>
        ) : undefined}
        {dev ? (
          <button
            onClick={() => {
              const today = new Date();
              const newDay = addDays(today, 1);
              changeDay(newDay);
            }}
          >
            Do tomorrow's
          </button>
        ) : undefined} */}
      {dev ? (
        <button
          onClick={() => {
            localStorage.clear();
          }}
        >
          Clear Local Storage
        </button>
      ) : undefined}
    </SettingsWrapper>
  );
}

// function useChangeDay(): (date: Date) => void {
//   const [, setDate] = useRecoilState(puzzleDate);
//   const resetSelection = useResetRecoilState(selectedIndexAtom);
//   return (date: Date) => {
//     setDate(date);
//     resetSelection();
//   };
// }
