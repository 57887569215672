import PuzzleBoard from "../puzzle/board/PuzzleBoard";
import ReadOnlyPuzzleCell from "../puzzle/board/ReadOnlyPuzzleCell";
import { PuzzleCellContent } from "../puzzle/interactivePuzzle/InteractivePuzzleCell";
import { BoardModel } from "../puzzle/selectors/puzzleSelectors";
import {
  indexToKey,
  PuzzleIndex,
} from "../puzzle/selectors/userPuzzleSelectors";
import { DisplayedTile } from "../puzzle/types/displayedTile";

export default function ViewOnlyPuzzleBoard({
  board,
  tilesByIndex,
  uniqueId,
}: {
  uniqueId: string;
  board: BoardModel;
  tilesByIndex: { [index: string]: DisplayedTile };
}) {
  function content(index: PuzzleIndex): PuzzleCellContent {
    const tile = tilesByIndex[indexToKey(index)];
    if (!tile) return "empty";
    return tile;
  }

  return (
    <PuzzleBoard board={board} puzzleKey={uniqueId}>
      {(key, size, index) => {
        return (
          <ReadOnlyPuzzleCell
            key={key}
            size={size}
            content={content(index)}
          ></ReadOnlyPuzzleCell>
        );
      }}
    </PuzzleBoard>
  );
}
