import { selector } from "recoil";
import { characterValues, ValidCharacter } from "./puzzleSelectors";
import { tileRack } from "./puzzleStateSelectors";
import { boardWords } from "./puzzleWordsSelectors";

export const wordScore = selector<number>({
  key: "wordScore",
  get: ({ get }) => {
    const words = get(boardWords);
    let total = 0;
    words.forEach((word) => {
      total += word.characters
        .split("")
        .map((c) => characterValues[c as ValidCharacter])
        .reduce((c, v) => c + v, 0);
    });
    return total;
  },
});

export const bonusScore = selector<number>({
  key: "bonusScore",
  get: ({ get }) => {
    const words = get(boardWords);
    let total = 0;
    words.forEach((word) => {
      total += bonus(word.characters.length);
    });
    return total;
  },
});

export const unusedLettersScore = selector<number>({
  key: "unusedLettersScore",
  get: ({ get }) => {
    return get(tileRack)
      .map((t) => characterValues[t.character as ValidCharacter])
      .reduce((c, v) => c - v, 0);
  },
});

export const puzzleScore = selector<number>({
  key: "puzzleScore",
  get: ({ get }) => {
    const words = get(wordScore);
    const bonuses = get(bonusScore);
    const unusedLetters = get(unusedLettersScore);
    return words + bonuses + unusedLetters;
  },
});

export function bonus(length: number) {
  // if (length < 5) return 0;
  // return (length * length + length) / 2 - 12;
  const nMinus1 = length - 1;
  const bonus = (nMinus1 * nMinus1 + nMinus1) / 2 - 5;
  return Math.max(0, bonus);
}
