import { useRecoilState, useRecoilValue } from "recoil";
import { selectedIndexAtom } from "../selectors/puzzleSelectionSelectors";
import { boardState } from "../selectors/puzzleStateSelectors";
import { indexToKey } from "../selectors/userPuzzleSelectors";
export default function useMoveSelectedIndex(): (
  dx: number,
  dy: number
) => void {
  const [curLocation, setCurLocation] = useRecoilState(selectedIndexAtom);
  const boardMap = useRecoilValue(boardState);
  return (dx: number, dy: number) => {
    const loc = { x: curLocation.x + dx, y: curLocation.y + dy };
    const exist = !!boardMap[indexToKey(loc)];
    if (exist) {
      setCurLocation(loc);
    }
  };
}
