import { createGlobalStyle } from "styled-components";

export const NoScroll = createGlobalStyle`
  body {
    overflow: hidden;
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
`;

export const BodyFullHeight = createGlobalStyle`
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
`;

export const ThemedBackground = createGlobalStyle`
  html, body {
    background: ${(p) => p.theme.colors.background.secondary};
    background-position: fixed;
  }
`;
