import { useCallback } from "react";
import { useRecoilState } from "recoil";
import moveToEndOfRack from "../actions/moveToEndOfRack";
import { PuzzleCellModel } from "../interactivePuzzle/InteractivePuzzleCell";
import {
  selectedDirectionAtom,
  selectedIndexAtom,
} from "../selectors/puzzleSelectionSelectors";
import { useUpdateUserPuzzle } from "../selectors/userPuzzleHistory";
import { DisplayedTile } from "../types/displayedTile";

export default function useInteractiveCellTapped(): (
  cell: PuzzleCellModel
) => void {
  const [, setSelectedIndex] = useRecoilState(selectedIndexAtom);
  const [, setDirection] = useRecoilState(selectedDirectionAtom);
  const updateUserPuzzle = useUpdateUserPuzzle();

  return useCallback(
    (cell) => {
      const selected = cell.selection !== undefined;
      const occupied = cell.content !== "empty";
      if (selected && occupied) {
        const content = cell.content as DisplayedTile;
        updateUserPuzzle((current) =>
          moveToEndOfRack(content.tile.id, current)
        );
      } else if (selected) {
        setDirection((current) => (current === "down" ? "right" : "down"));
      } else {
        setSelectedIndex(cell.index);
      }
    },
    [setDirection, setSelectedIndex, updateUserPuzzle]
  );
}
