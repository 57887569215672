import styled from "styled-components";
import { BodyFullHeight, ThemedBackground } from "../../common/GlobalStyles";
import { PagesContent, PagesWrapper } from "../../common/Pages";
import TutorialButton from "../components/TutorialButton";
import TutorialFooter from "../components/TutorialFooter";
import TutorialHeader from "../components/TutorialHeader";
import Tip1 from "./Tip1";
import Tip2 from "./Tip2";
import Tip3 from "./Tip3";
import Tip4 from "./Tip4";
import TipsIntro from "./TipsIntro";

const TipsWrapper = styled.div`
  background: ${(p) => p.theme.colors.background.board};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
`;

const TutorialTipsContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  pointer-events: none;
`;

export default function TutorialTips({
  onBack,
  onNext,
  onClose,
}: {
  onBack: () => void;
  onClose: () => void;
  onNext: () => void;
}) {
  return (
    <PagesWrapper>
      {(page, direction, previous, next) => (
        <TipsWrapper>
          <BodyFullHeight />
          <ThemedBackground />
          <TutorialHeader onClose={onClose} showBackground={true}>
            {page === 0 ? "Tips" : `Tip #${page}`}
          </TutorialHeader>
          <TutorialTipsContent>
            <PagesContent page={page} direction={direction}>
              <TipsIntro></TipsIntro>
              <Tip1></Tip1>
              <Tip2></Tip2>
              <Tip3></Tip3>
              <Tip4></Tip4>,
            </PagesContent>
          </TutorialTipsContent>
          <TutorialFooter
            onBack={() => {
              if (page === 0) {
                onBack();
              } else {
                previous();
              }
            }}
          >
            <TutorialButton
              onClick={() => {
                if (page < 4) {
                  next();
                } else {
                  onNext();
                }
              }}
            >
              {page === 4 ? "Done" : "Next"}
            </TutorialButton>
          </TutorialFooter>
        </TipsWrapper>
      )}
    </PagesWrapper>
  );
}
