import { selector } from "recoil";
import { TileModel } from "./puzzleSelectors";
import { boardState, BoardStateMap } from "./puzzleStateSelectors";
import { indexToKey, keyToIndex, PuzzleIndex } from "./userPuzzleSelectors";
import { validWord } from "./Words";

export interface BoardWord {
  start: PuzzleIndex;
  direction: "down" | "right" | "solo";
  characters: string;
  valid: boolean;
}

function adjacentIndex(location: string, dx: number, dy: number): string {
  const loc = keyToIndex(location);
  const x = loc.x + dx;
  const y = loc.y + dy;
  return indexToKey({ x, y });
}

function word(
  map: { [location: string]: TileModel | "empty" },
  location: string,
  dx: number,
  dy: number
): string {
  let item = map[location];
  if (!item || item === "empty") return "";
  return item.character + word(map, adjacentIndex(location, dx, dy), dx, dy);
}

function tile(
  map: { [location: string]: TileModel | "empty" },
  location: string
) {
  const item = map[location];
  if (item === "empty") return undefined;
  return item;
}

function wordsAtLocation(
  location: string,
  map: { [location: string]: TileModel | "empty" }
): BoardWord[] {
  const value = map[location];
  if (value === "empty") return [];
  const left = tile(map, adjacentIndex(location, -1, 0));
  const above = tile(map, adjacentIndex(location, 0, -1));

  const rightWord: string | undefined = left
    ? undefined
    : word(map, location, 1, 0);

  const downWord: string | undefined = above
    ? undefined
    : word(map, location, 0, 1);

  if (rightWord?.length === 1 && downWord?.length === 1) {
    return [
      {
        start: keyToIndex(location),
        direction: "solo",
        characters: rightWord,
        valid: validWord(rightWord),
      },
    ];
  }

  var output: BoardWord[] = [];
  if (rightWord && rightWord.length > 1) {
    output.push({
      start: keyToIndex(location),
      direction: "right",
      characters: rightWord,
      valid: validWord(rightWord),
    });
  }
  if (downWord && downWord.length > 1) {
    output.push({
      start: keyToIndex(location),
      direction: "down",
      characters: downWord,
      valid: validWord(downWord),
    });
  }
  return output;
}

export function findWords(map: BoardStateMap): BoardWord[] {
  return Object.keys(map).flatMap((location) => {
    return wordsAtLocation(location, map);
  });
}

export const boardWords = selector<BoardWord[]>({
  key: "boardWords",
  get: ({ get }) => {
    const tiles = get(boardState);
    return findWords(tiles);
  },
});
