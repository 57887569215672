import styled from "styled-components";
import { RegularText } from "../../common/CommonElements";
import { colorForState } from "../../puzzle/board/CharacterTile";
import { DisplayedTileState } from "../../puzzle/types/displayedTile";
import ExampleGrid from "./ExampleGrid";
import { TipCell, TipContent, TipHeader, TipRow } from "./TipComponents";

export default function Tip1() {
  const board = "XXX XXX XXX";
  return (
    <div>
      <TipHeader>Pay attention to the colors</TipHeader>
      <TipContent>
        <TipRow>
          <LabeledGridTip1
            boardStr={board}
            state="default"
            tilesStr="oewh"
            tileIndices={["0,0", "0,1", "1,1", "2,2"]}
            title="Invalid"
            titleBelow={false}
          ></LabeledGridTip1>
          <LabeledGridTip1
            boardStr={board}
            state="valid"
            tilesStr="ohwe"
            tileIndices={["0,0", "1,0", "2,1", "2,2"]}
            title="Valid Words"
            titleBelow={false}
          ></LabeledGridTip1>
        </TipRow>
        <TipRow>
          <LabeledGridTip1
            boardStr={board}
            state="ready"
            tilesStr="howe"
            tileIndices={["0,1", "1,1", "2,1", "2,2"]}
            title="Valid Puzzle"
            titleBelow={true}
          ></LabeledGridTip1>
          <LabeledGridTip1
            boardStr={board}
            state="complete"
            tilesStr="owhe"
            tileIndices={["1,1", "2,1", "1,2", "2,2"]}
            title="Submitted"
            titleBelow={true}
          ></LabeledGridTip1>
        </TipRow>
      </TipContent>
    </div>
  );
}

const StateColoredText = styled(RegularText)<{ state: DisplayedTileState }>`
  text-align: center;
  color: ${(p) => colorForState(p.state, p.theme)};
`;

function LabeledGridTip1({
  titleBelow,
  title,
  state,
  boardStr,
  tilesStr,
  tileIndices,
}: {
  tileIndices: string[];
  boardStr: string;
  tilesStr: string;
  state: DisplayedTileState;
  titleBelow: boolean;
  title: string;
}) {
  return (
    <TipCell>
      {titleBelow ? undefined : (
        <StateColoredText state={state}>{title}</StateColoredText>
      )}
      <ExampleGrid
        state={state}
        boardStr={boardStr}
        tilesStr={tilesStr}
        tileIndices={tileIndices}
      />
      {titleBelow ? (
        <StateColoredText state={state}>{title}</StateColoredText>
      ) : undefined}
    </TipCell>
  );
}
