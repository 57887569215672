import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useCanGoBack } from "../../global/hooks/useMaybeBackButton";
import { BodyFullHeight, ThemedBackground } from "../common/GlobalStyles";
import { Pages } from "../common/Pages";
import TutorialTips from "./tips/TutorialTips";
import TutorialComplete from "./TutorialComplete";
import TutorialInteractive from "./TutorialInteractive";
import TutorialLanding from "./TutorialLanding";

const TutorialWrapper = styled.div`
  background: ${(p) => p.theme.colors.background.main};
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default function Tutorial() {
  return (
    <TutorialWrapper>
      <BodyFullHeight />
      <ThemedBackground />
      <AnimatePresence>
        <TutorialPages />
      </AnimatePresence>
    </TutorialWrapper>
  );
}

function TutorialPages() {
  const canGoBack = useCanGoBack();
  const navigate = useNavigate();
  const onClose = () => {
    if (canGoBack) navigate(-1);
    else navigate("/");
  };
  return (
    <Pages>
      {(previous, next) => {
        return [
          <TutorialLanding key="tutorial-1" onStart={next} onClose={onClose} />,
          <TutorialInteractive
            key="tutorial-2"
            onBack={previous}
            onClose={onClose}
            onNext={next}
          />,
          <TutorialTips
            key="tutorial-3"
            onBack={previous}
            onClose={onClose}
            onNext={next}
          ></TutorialTips>,
          <TutorialComplete
            key="tutorial-4"
            onClose={onClose}
          ></TutorialComplete>,
        ];
      }}
    </Pages>
  );
}
