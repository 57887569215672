import {
  GridCellContentWrapper,
  PuzzleCellContent,
  PuzzleCellWrapper,
} from "../interactivePuzzle/InteractivePuzzleCell";
import CharacterTile from "./CharacterTile";

export default function ReadOnlyPuzzleCell({
  size,
  content,
}: {
  size: number;
  content: PuzzleCellContent;
}) {
  return (
    <PuzzleCellWrapper size={size}>
      <GridCellContentWrapper size={size} highlight={false}>
        {content !== "empty" ? (
          <CharacterTile tile={content} size={size}></CharacterTile>
        ) : undefined}
      </GridCellContentWrapper>
    </PuzzleCellWrapper>
  );
}
