import React from "react";
import {
  MdBackspace,
  MdRedo,
  MdRefresh,
  MdShuffle,
  MdUndo,
} from "react-icons/md";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { useConfirmAlert } from "../../../common/Alerts";
import { IconButton, Spacer } from "../../../common/CommonElements";
import rackAllTiles from "../../actions/rackAllTiles";
import shuffleRackedTiles from "../../actions/shuffleRackedTiles";
import useBackspace from "../../hooks/useBackspace";
import {
  previousboardIndex,
  selectedLocationTileSelector,
} from "../../selectors/puzzleSelectionSelectors";
import { puzzleAtom } from "../../selectors/puzzleSelectors";
import { tileRack } from "../../selectors/puzzleStateSelectors";
import {
  useUndoUserPuzzle,
  useUpdateUserPuzzle,
} from "../../selectors/userPuzzleHistory";
import SubmitButton from "./SubmitButton";

const ActionsWrapper = styled.div`
  padding: 16px;
  padding-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ActionIconButton = styled(IconButton)`
  margin-right: 12px;
  font-size: 30px;
  display: flex;
`;

const SubmitButtonWrapper = styled.div`
  margin-left: 15px;
`;

export default function FooterActions() {
  const updateUserPuzzle = useUpdateUserPuzzle();

  const [confirmResetAlert, showConfirmReset] = useConfirmAlert(
    "Are you sure you want to clear the board and start over?",
    () => updateUserPuzzle(rackAllTiles),
    "Reset",
    "Reset"
  );

  const rack = useRecoilValue(tileRack);
  const tiles = useRecoilValue(puzzleAtom).tiles;
  const prev = useRecoilValue(previousboardIndex);
  const currentTile = useRecoilValue(selectedLocationTileSelector);
  const backspace = useBackspace();

  const { canUndo, canRedo, undo, redo } = useUndoUserPuzzle();

  return (
    <ActionsWrapper>
      {confirmResetAlert}

      <ActionIconButton
        colorize={true}
        disabled={rack.length === tiles.length}
        onClick={() => showConfirmReset(true)}
      >
        <MdRefresh></MdRefresh>
      </ActionIconButton>
      <ActionIconButton
        colorize={true}
        onClick={() => updateUserPuzzle(shuffleRackedTiles)}
      >
        <MdShuffle></MdShuffle>
      </ActionIconButton>
      <ActionIconButton
        colorize={true}
        disabled={prev === undefined && currentTile === undefined}
        onClick={backspace}
      >
        <MdBackspace></MdBackspace>
      </ActionIconButton>
      <Spacer></Spacer>
      <ActionIconButton colorize={true} disabled={!canUndo} onClick={undo}>
        <MdUndo />
      </ActionIconButton>
      <ActionIconButton colorize={true} disabled={!canRedo} onClick={redo}>
        <MdRedo />
      </ActionIconButton>
      <SubmitButtonWrapper>
        <SubmitButton></SubmitButton>
      </SubmitButtonWrapper>
    </ActionsWrapper>
  );
}
